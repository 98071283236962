import Button from 'components/Button'
import Container from 'components/Container'
import React from 'react'
import { translate } from 'utils/utils'

const Support = () => {
  return (
    <Container>
        <div className='container rounded p-3 mb-3'>
            <div className='d-flex g-3 align-items-center'>
                <i className='fa-solid fa-wrench' />
                <h3>{ translate('Technical services') }</h3>
            </div>
            <p className=' mb-3'>{ translate('Contact this in case of a problem in the website') }</p>
            <div className='d-flex g-3 flex-wrap'>
                <a target='_blank'  href='tel:0563413752'>
                    <Button outline className='g-3 rounded-4'><i className='fa-solid fa-phone' />0563413752</Button>
                </a>
                <a target='_blank'  href='mailto:hanotify.store@gmail.com'>
                    <Button outline className='g-3 rounded-4'><i className='fa-solid fa-envelope' />hanotify.store@gmail.com</Button>
                </a>
            </div>

        </div>
        <div className='container rounded p-3'>
            <div className='d-flex g-3 align-items-center'>
                <i className='fa-solid fa-user' />
                <h3>{ translate('Commercial services') }</h3>
            </div>
            <p className=' mb-3'>{ translate('Contact this in case you have any questions') }</p>
            <div className='d-flex g-3 flex-wrap'>
                <a target='_blank'  href='tel:0549049039'>
                    <Button outline className='g-3 rounded-4'><i className='fa-solid fa-phone' />0549049039</Button>
                </a>
                <a target='_blank' href='mailto:admin@hanotify.com'>
                    <Button outline className='g-3 rounded-4'><i className='fa-solid fa-envelope' />admin@hanotify.com</Button>
                </a>
            </div>
        </div>
    </Container>
  )
}

export default Support