import { apiUrl, wsDomain } from "constants/urls";
import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { axiosTr, translate } from "utils/utils";
import newOrderSound from "../assets/audio/cash-register.mp3";
import SetupGoogleFCM from "static/SetupGoogleFCM";
import { inDev } from "constants/Values";
import useInternetStatus from "hooks/useInternetStatus";
import Button from "components/Button";
import { useGlobalDialogContext } from "./global-context-provider";


const  UserContext = createContext({
    userData : null,
    setUserData : ()=>{},
})

let appParamasFromLocaleStorage = localStorage.getItem('appParamas')
if (!appParamasFromLocaleStorage) {
  appParamasFromLocaleStorage = {
    "soundMode": 'Loud'
  }
  localStorage.setItem('appParamas', appParamasFromLocaleStorage)
}
else{
  appParamasFromLocaleStorage = JSON.parse(appParamasFromLocaleStorage)
}

let userDataFromBrowser = JSON.parse(localStorage.getItem('userData'))


const UserContextProvider=({children})=>{
  const [userData, setUserData] = useState(userDataFromBrowser)
  const [loadingUserData, setLoadingUserData] = useState(true) 
  useEffect(()=>{
    if (userData) {
      localStorage.setItem('userData', JSON.stringify(userData))
      localStorage.setItem('token', userData.token)
      localStorage.setItem('storeId', userData.storeId)
    }
    else {
      localStorage.removeItem('userData')
      localStorage.removeItem('token')
      localStorage.removeItem('storeId')
    }
  }, [userData])

  useEffect(()=>{
    if(userData?.id){
      if('ReactNativeWebView' in window){
        window.ReactNativeWebView.postMessage(JSON.stringify({ 
          type: 'USER_DATA_CHANGED',
          userData: userData
        }))
      }
    }
  }, [userData?.id])

  const [storeCredit, setStoreCredit] = useState(null)

  const {setGlobalDialog} = useGlobalDialogContext()
  useEffect(()=>{
    const asyncUseEffect=async()=>{
      if(userData){ 
        try{
          const response = await axiosTr.get(
            apiUrl + `/user/logged-user-connected?token=${localStorage.getItem('token')}&store_id=${localStorage.getItem('storeId')}`,
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
              }
            }
          )
          setStoreCredit(response.data.user_data.credit)
          setUserData(userData=>({
            ...userData,
            loginDate:new Date(),
            ...response.data.user_data
          }))
        }
        catch(error){
          if (error.response?.data.detail === 'log-user-out') {
            setTimeout(()=>{
              setGlobalDialog(
                {
                  children: <h3 className="p-3">{ translate("You have been logged out") }</h3>,
                  keepOpen: true,
                  onClose: ()=>{
                    setUserData(null)
                    window.location.href = '/login'
                  }
                }
              )
              setTimeout(()=>{
                setUserData(null)
                window.location.href = '/login'
              }, 3000)
            }, 800)
          }
        }
      }
      setLoadingUserData(false)
    }
    asyncUseEffect()
  }, [])

  // Default value
  const [renderedOrders, setRenderedOrders] = useState([])
  const [socketIsActive, setSocketIsActive] = useState(null)

  // WS cofiguration
  const socket = useRef();
  const maxReconnectAttempts = 10
  const recconctionAttempts = useRef(0)
  const isOnline = useInternetStatus()
  const initWs=()=>{
    socket.current = new WebSocket(
      `${wsDomain}/ws/store/?store_id=${localStorage.getItem(
        "storeId"
      )}&token=${localStorage.getItem("token")}`
    );

    socket.current.onopen = function (e) {
      console.log("WebSocket connection opened");
      setSocketIsActive(true)
      recconctionAttempts.current = 0
    };

    socket.current.onmessage = function (e) {
      const data = JSON.parse(e.data);
      if (data.type == 'new-order'){
        setRenderedOrders((renderedOrders) => [data.order, ...renderedOrders]);
        const audio = new Audio(newOrderSound);  
        soundMode === 'Loud' && audio.play();
      }
      if (data.type == 'log-user-out'){
        setTimeout(()=>{
          setGlobalDialog(
            {
              children: <h3 className="p-3">{ translate("You have been logged out") }</h3>,
              keepOpen: true,
              onClose: ()=>{
                setUserData(null)
                window.location.href = '/login'
              }
            }
          )
          setTimeout(()=>{
            setUserData(null)
            window.location.href = '/login'
          }, 3000)
        }, 800)
      }
    };

    socket.current.onclose = function (e) {
      console.log("WebSocket connection closed");
      setSocketIsActive(false)
      if(!inDev){
        if (isOnline && recconctionAttempts.current < maxReconnectAttempts){
          recconctionAttempts.current += 1
          setTimeout(initWs, 1000)
        }
      }
    };

    socket.current.onerror = function (e) {
      console.error("WebSocket error observed:", e);
    }
  }
  useEffect(() => {
    if(userData?.storeId){
      initWs()
    }
    else{
      if(socket.current) socket.current?.close()
    }
    return () => {
      socket.current?.close()
    }
  }, [userData?.storeId]);

  const [appParamas, setAppParamas] = useState(appParamasFromLocaleStorage)
  useEffect(()=>{
    localStorage.setItem('appParamas', JSON.stringify(appParamas)) 
  }, [appParamas])

  const [soundMode, setSoundMode] = useState(appParamas.soundMode)
  useEffect(()=>{
    setAppParamas(appParamas=>({
      ...appParamas,
      soundMode
    }))
  }, [soundMode])

  // sidebar content
  const sidebarElemnts = useMemo(()=>[
    {
      label: translate("User"),
      iconClass: "fa-solid fa-user",
      link: '/user',
      childrenData:[
        {
            label: translate("Account settings"),
            link: '/user/account-settings',
        },
        {
            label: translate("Your stores"),
            link: '/user/your-stores',
        },
    ]
    },
    {
        label: translate("Dashboard"),
        iconClass: "fa-solid fa-house",
        link: '/dashboard',
    },
    {
        label: translate("Orders"),
        iconClass: "fa-solid fa-box",
        link: '/orders',
        childrenData:[
            {
                label: translate("Orders"),
                link: '/orders',
            },
            {
                label: translate("Abandoned orders"),
                link: '/orders/abandoned-orders',
            },
            {
              label: translate("Archived orders"),
              link: '/orders/archived-orders',
          },
        ],
        endLink: 'https://www.youtube.com/watch?v=2Msygb2WQdc'
    },
    {
        label: translate("Products"),
        iconClass: "fa-solid fa-tags",
        link: '/products',
        childrenData:[
          {
              label: translate("Your products"),
              link: '/products',
          },
          {
            label: translate("Add product"),
            link: '/products/add',
            anchor: true,
          },
          {
            label: translate("Categories"),
            link: '/store/categories',
          },
          {
            label: translate("Form settings"),
            link: '/store/customize-form',
          },
        ],
        endLink: 'https://www.youtube.com/watch?v=XBXUfQNESaQ'
    },
    {
        label: translate("Store"),
        iconClass: "fa-solid fa-store",
        link: '/store',
        childrenData:[
          {
            label: translate("Settings"),
            link: '/store/settings',
          },
          {
              label: translate("General design"),
              link: '/store/design',
          },
          {
            label: translate("Pages"),
            link: '/store/pages',
            anchor: true,
            endLink: 'https://www.youtube.com/watch?v=Q4_Fvb8OJ7A'
          },
          {
            label: translate("Navigation"),
            link: '/store/components-design',
          },
          {
            label: translate("Socials"),
            link: '/store/socials',
          },
          {
            label: translate("Customize domain"),
            link: '/store/custom-domain',
          },
      ]
    },
    {
      label: translate("Apps"),
      iconClass: "fa-solid fa-puzzle-piece",
      link: '/apps',
    },
    // {
    //     label: translate("Statistics"),
    //     iconClass: "fa-solid fa-chart-line",
    //     link: '/stats',
    // },
    {
        label: translate("Payment"),
        iconClass: "fa-solid fa-credit-card",
        link: '/payment',
    },
    {
      label: translate("Plans"),
      iconClass: "fa-solid fa-list-check",
      link: '/plans',
  },
  {
      label: translate("Settings"),
      iconClass: "fa-solid fa-gear",
      link: '/settings',
  },
  {
      label: translate("Support"),
      iconClass: "fa-solid fa-headset",
      link: '/support',
  },
  {
    label: translate("Affiliate"),
    iconClass: "fa-solid fa-square-share-nodes",
    link: '/affiliate',
  },
  ], [])

  const defaultUserContextValue={
    userData,
    setUserData,
    storeCredit,
    renderedOrders, setRenderedOrders,
    socketIsActive, setSocketIsActive,
    soundMode, setSoundMode,
    setStoreCredit,
    initWs,
    sidebarElemnts,
    loadingUserData,
  }
  return (
      <UserContext.Provider value={defaultUserContextValue}  key={userData?.storeId}>
          { userData && ('serviceWorker' in navigator ) && ('Notification' in window) && <SetupGoogleFCM /> }
          { children}
          {
            userData && !isOnline &&
            <Button onClick={initWs} outline style={{ position: 'fixed', left: 12, bottom: 12, borderRadius: 12, padding: '12px 10px', borderRadius:'50%', display:'flex' }}>
              <i className='fa-solid fa-wifi' style={{fontSize:20}} />
              <div style={{ position: 'absolute', height:32, width:3, borderRadius: 4, backgroundColor: 'var(--containerColor)', transform: 'rotate(45deg)', marginBottom: 2}}/>
            </Button>
          }
      </UserContext.Provider>
  )
}
export default UserContextProvider


const useUserContext =()=> useContext(UserContext)
export {useUserContext}