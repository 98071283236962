import IconWithHover from "components/IconWithHover"
import DialogComponent from "components/tags/Dialog"
import { useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import { useGlobalDialogContext } from "store/global-context-provider"


const GlobalDialog = () => {
    const {globalDialog, setGlobalDialog} = useGlobalDialogContext()
    const location = useLocation()
    useEffect(()=>{
        if(!globalDialog?.keepOpen){
            // dialogRef.current?.close()
        }
    }, [location.pathname])

    const dialogRef = useRef()
    
    const onClose=()=>{
        globalDialog?.onClose && globalDialog.onClose()
        setGlobalDialog(null)
    }
    return(
        <DialogComponent ref={dialogRef} open={globalDialog} close={onClose}>
            <div className="p-3">
                <div className="justify-content-end d-flex mb-2">
                    <IconWithHover size={24} iconClass="fa-solid fa-xmark" onClick={onClose} />    
                </div>
                {globalDialog?.children}
            </div>
            
        </DialogComponent>
    )
}

export default GlobalDialog