import { apiUrl } from "constants/urls"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useBrowserContext } from "store/browser-context"
import { useUserContext } from "store/user-context"
import { axiosTr, translate } from "utils/utils"

const useLogout = () => {
    const {userData, setUserData} = useUserContext()
    const {setGlobalMessageA} = useBrowserContext()
  
    const navigate = useNavigate()

  
    const [loading, setloading]=useState(false)
    const logout=async()=>{
        setloading(true)
        try{
          await axiosTr.post(
            apiUrl + "/user/logout", 
            {
              token: userData.token
            },
            {
                headers : {
                  "Content-Type": "application/json",
                }
            }
          )
          setUserData(null)
          navigate('/login/')
          setloading(false)
          // logout from mobile app
          if ('serviceWorker' in navigator){
            if(window.deleteToken) window.deleteToken()
          }
          if('ReactNativeWebView' in window){
            window.ReactNativeWebView.postMessage(JSON.stringify({ 
              type: 'USER_LOGGED_OUT',
            }))
          }
          return true
        }catch(error){
          console.error(error)
          setGlobalMessageA({
            color: 'red',
            time: 3000,
            children: translate('Logout Failed')
          })
          setloading(false)
          return false
        }
        
    }
    return {logout, loading}
}

export default useLogout