import React, { lazy, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import classes from './Container.module.css';
import Header from './Header';
import { useUserContext } from '../../store/user-context';
import { useBrowserContext } from 'store/browser-context';
import SuspenseComponent from 'components/SuspenseComponent';


const Sidebar  = lazy(()=>import('./sidebar/Sidebar') )
const WideSideBar = lazy(()=>import('./wide-side-bar/WideSideBar') )

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  return null; // This component doesn't render anything
};

const Container = () => {
  const {openSideBar, setOpenSideBar} = useBrowserContext()
  const {userData} = useUserContext()

  const {screenWidth} = useBrowserContext()
  const smallScreen = screenWidth <= 980
  return (
    <div className='d-flex'>
      {
        userData && !smallScreen && <SuspenseComponent Component={WideSideBar} openSideBar={openSideBar} setOpenSideBar={setOpenSideBar} />
      }
      <ScrollToTop/>
      <div className={ classes.container}>
        <Header setOpen={setOpenSideBar}/>
          {userData && smallScreen && <SuspenseComponent Component={Sidebar} open={openSideBar} onClickBackdrop={()=>setOpenSideBar(false)} />}
          <div className={'d-flex flex-1' + ((!smallScreen) ? ( openSideBar ? ' sidebar-open-2' : ' sidebar-open-1' ) : '' )}>
           
            <div className={classes.content}>
              <Outlet/>
            </div>
          </div>
      </div>
    </div>
      
      
  )
}

export default Container