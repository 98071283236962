import React from 'react'

const Container = ({width=920, notFullHeight=false, noFlex=false, noPadding, bottomSpace=false, ...props}) => {
  return (
    <div style={{flex: notFullHeight ? undefined : 1 , display: noFlex ? undefined:  'flex', justifyContent: 'center', padding: 8, marginBottom: bottomSpace ? 62 : undefined}}>
        <div className='flex-1' style={{maxWidth: `min(${width}px, 100%)`}}>
            <div {...props} className={`container h-100 p-relative flex-column ${ noPadding ? '': 'p-2 '}` + props.className} />
        </div>
    </div>
  )
}

export default Container