import axios from "axios";
import { filesUrl } from "constants/urls";
import heic2any from "heic2any";
import imageCompression from 'browser-image-compression';


const dict ={
  'ar':{
    "Home": "المنزل",
    "apply to all": "تطبيق على الكل",
    "Office": "المكتب",
    "Search a state": "ابحث عن ولاية",
    "Image was not uploaded because its size is too large (over 10MB)": "لم يتم تحميل الصورة لأن حجمها كبير جدًا (أكثر من 10 ميجابايت)",
    "Image was not uploaded": "لم يتم تحميل الصورة",
    "DA": "دج",
    "User": "المستخدم",
    "Account settings": "إعدادات الحساب",
    "Your stores": "متاجرك",
    "Dashboard": "لوحة التحكم",
    "Orders": "الطلبات",
    "Obandened orders": "الطلبات المتروكة",
    "Archived orders": "الطلبات المؤرشفة",
    "Products": "المنتجات",
    "Your products": "منتجاتك",
    "Add product": "إضافة منتج",
    "Store": "المتجر",
    "Settings": "الإعدادات",
    "General design": "التصميم العام",
    "Pages": "الصفحات",
    "Navigation": "التنقل",
    "Categories": "الفئات",
    "Socials": "وسائل التواصل الاجتماعي",
    "Customize domain": "تخصيص النطاق",
    "Apps": "التطبيقات",
    "Statistics": "الإحصائيات",
    "Payment": "الدفع",
    "Plans": "الخطط",
    "Support": "الدعم",
    "Download Android app": "تنزيل تطبيق Android",
    "Logout": "تسجيل الخروج",
    "Logout Failed": "فشل تسجيل الخروج",
    "First name, last name and username": "الاسم، اللقب واسم المستخدم",
    "Email": "البريد الإلكتروني",
    "Password": "كلمة المرور",
    "Phone number": "رقم الهاتف",
    "Your product was not listed": "لم يتم إدراج منتجك",
    "Infomation": "معلومات",
    "Variants": "المتغيرات",
    "Shipping": "الشحن",
    "Save": "حفظ",
    "Google Sheets": "جوجل شيتس",
    "Google Sheets is a spreadsheet app used to organize, format, and calculate your orders.": "جوجل شيتس هو تطبيق جداول بيانات يستخدم لتنظيم وتنسيق وحساب طلباتك.",
    "Facebook pixel": "بكسل فيسبوك",
    "Add facebok pixel to your store to improve our conversion rate.": "أضف بكسل فيسبوك إلى متجرك لتحسين معدل التحويل لدينا.",
    "Add facebook conversions api to your store to improve our conversion rate.": "أضف facebook conversions api إلى متجرك لتحسين معدل التحويل لدينا.",
    "Tiktok pixels": "بكسل تيك توك",
    "Add tiktok pixel to your store to improve our conversion rate from your tiktok ad account.": "أضف بكسل تيك توك إلى متجرك لتحسين معدل التحويل لدينا من حسابك الإعلاني على تيك توك.",
    "Add tiktok events api to your store to improve our conversion rate.": "أضف واجهة برمجة تطبيقات أحداث تيك توك إلى متجرك لتحسين معدل التحويل لدينا.",
    "Messaging apps": "تطبيقات المراسلة",
    "send confirmation messages to messaging apps when someone makes an order to increase confirmed orders": "إرسال رسائل تأكيد إلى تطبيقات المراسلة عندما يقوم شخص ما بطلب لزيادة الطلبات المؤكدة",
    "Welcome to the dashboard!": "مرحبًا بكم في لوحة التحكم!",
    "As a new user you have 15 days of the Advanced plan, Enjoy": "كمستخدم جديد، لديك 15 يومًا من الخطة المتقدمة، استمتع!",
    "Your current store domain is": "النطاق الحالي لمتجرك هو",
    "Change domain": "تغيير النطاق",
    "Setup your account": "إعداد حسابك",
    "Last 24 hours orders": "طلبات آخر 24 ساعة",
    "Last 24 hours": "آخر 24 ساعة",
    "Last week orders": "طلبات الأسبوع الماضي",
    "Last month orders": "طلبات الشهر الماضي",
    "All time orders": "الطلبات منذ البداية",
    "Last 24 hours obendended orders": "طلبات متروكة في آخر 24 ساعة",
    "Last 24 hours confirmed orders": "طلبات مؤكدة في آخر 24 ساعة",
    "Last 24 hours cancelled orders": "طلبات ملغاة في آخر 24 ساعة",
    "Login": "تسجيل الدخول",
    "Register": "التسجيل",
    "Set-up your store in just few clicks": "قم بإعداد متجرك ببضع نقرات فقط",
    "Get started now": "ابدأ الآن",
    "Hanotify": "Hanotify",
    "Login to your account at Hanotify.": "سجل الدخول إلى حسابك على Hanotify.",
    "Go back home page": "العودة إلى الصفحة الرئيسية",
    "Login to your account at Hanotify": "سجل الدخول إلى حسابك على Hanotify",
    "Username": "اسم المستخدم",
    "Did you forget your password ?": "هل نسيت كلمة المرور؟",
    "You do not have an account?": "ليس لديك حساب؟",
    "Product": "المنتج",
    "Number": "الرقم",
    "Status": "الحالة",
    "Total": "الإجمالي",
    "Full name": "الاسم الكامل",
    "State": "الولاية",
    "Commune": "البلدية",
    "Qnty": "الكم",
    "Date": "التاريخ",
    "Ad": "إعلان",
    "Erro while getting the phone number": "خطأ أثناء الحصول على رقم الهاتف",
    "Edit": "تعديل",
    "Delete": "حذف",
    "Bump": "رفع",
    "Unobandon": "عدم التخلي",
    "Are you sure you want to delete this option?": "هل أنت متأكد أنك تريد حذف هذا الخيار؟",
    "Order id": "معرف الطلب",
    "Product cost": "تكلفة المنتج",
    "Quantity": "الكمية",
    "Ad comapany": "شركة الإعلان",
    "Shipping cost": "تكلفة الشحن",
    "Total price": "السعر الإجمالي",
    "Address": "العنوان",
    "Client note": "ملاحظة العميل",
    "Seller note": "ملاحظة البائع",
    "Client": "العميل",
    "IP Addresses": "عناوين IP",
    "Unblock visitor": "إلغاء حظر الزائر",
    "Block Visitor": "حظر الزائر",
    "DZD": "دج",
    "Add a note": "إضافة ملاحظة",
    "Submit Payment": "إرسال الدفع",
    "Choose a payment method": "اختر طريقة الدفع",
    "Your plan": "خطتك",
    "Exipation date": "تاريخ الانتهاء",
    "No expiration date": "لا يوجد تاريخ انتهاء",
    "Orders left": "الطلبات المتبقية",
    "Infinite": "غير محدود",
    "Abendened Orders left": "الطلبات المتروكة المتبقية",
    "Facebook pixels": "بكسل فيسبوك",
    "Google sheets accounts": "حسابات جوجل شيتس",
    "Send to shipping companies in bulk": "إرسال إلى شركات الشحن بكميات كبيرة",
    "Send orders with messaging apps(What's app, viber and telegram)": "إرسال الطلبات باستخدام تطبيقات المراسلة (واتساب، فايبر، وتليجرام)",
    "Show the ad company of each order": "عرض شركة الإعلان لكل طلب",
    "Hide hanotify logo from your store": "إخفاء شعار Hanotify من متجرك",
    "Custom domain": "نطاق مخصص",
    "DZ":"دج",
    "Month": "الشهر",
    "Off": "خصم",
    "Buy now": "اشتري الآن",
    "Exit": "خروج",
    "month": "شهر",
    "Abendened Orders": "الطلبات المتروكة",
    "Google sheet accounts": "حسابات جوجل شيت",
    "Send orders with messaging apps (What's app, viber and telegram)": "إرسال الطلبات باستخدام تطبيقات المراسلة (واتساب، فايبر، وتليجرام)",
    "Extend your plan": "تمديد خطتك",
    "Activate this plan": "تفعيل هذه الخطة",
    "Our plans": "خططنا",
    "Since this is your current plan you do not need buy this now it will be automaitcally reactived when the plan expires": "نظرًا لأن هذه هي خطتك الحالية، لا تحتاج إلى شرائها الآن، سيتم إعادة تفعيلها تلقائيًا عند انتهاء الخطة.",
    "Your product was deleted successfully": "تم حذف منتجك بنجاح",
    "Your product was not deleted": "لم يتم حذف منتجك",
    "Pieces": "قطع",
    "Duplicate product": "تكرار المنتج",
    "Product page": "صفحة المنتج",
    "landing page": "صفحة الهبوط",
    "Deactivate": "إلغاء التفعيل",
    "Activate": "تفعيل",
    "Are you sure you want to delete this product?": "هل أنت متأكد أنك تريد حذف هذا المنتج؟",
    "Are you sure you want to duplicate this product?": "هل أنت متأكد أنك تريد تكرار هذا المنتج؟",
    "Select all": "تحديد الكل",
    "Are you sure you want to delete these products?": "هل أنت متأكد أنك تريد حذف هذه المنتجات؟",
    "Reload": "إعادة التحميل",
    "Register an account at Hanotify": "تسجيل حساب في Hanotify",
    "Your first name must be at least 3 latters long": "يجب أن يكون اسمك مكون من 3 أحرف على الأقل",
    "Your last name must be at least 3 latters long": "يجب أن يكون اسمك الأخير مكون من 3 أحرف على الأقل",
    "Your password must be at least 8 latters long": "يجب أن تكون كلمة المرور مكونة من 8 أحرف على الأقل",
    "Your passwords do not macth": "كلمات المرور غير متطابقة",
    "First name": "الاسم",
    "Last name": "اللقب",
    "Confirm password": "تأكيد كلمة المرور",
    "this username is taken": "اسم المستخدم هذا محجوز",
    "this username is all yours": "اسم المستخدم هذا لك",
    "Next": "التالي",
    "Go back": "العودة",
    "You already have an account?": "هل لديك حساب بالفعل؟",
    "Reset your password": "إعادة تعيين كلمة المرور",
    "Please write the email of your account": "يرجى كتابة البريد الإلكتروني لحسابك",
    "Send password to email": "إرسال كلمة المرور إلى البريد الإلكتروني",
    "Go back to login page": "العودة إلى صفحة تسجيل الدخول",
    "Shipping costs": "تكاليف الشحن",
    "Personlize your shipping cost according to the states": "تخصيص تكلفة الشحن حسب الولايات",
    "Shipping Companies": "شركات الشحن",
    "Connect your store to other shipping companies": "ربط متجرك بشركات شحن أخرى",
    "Category": "الفئة",
    "Thank you page": "صفحة الشكر",
    "Privacy policy": "سياسة الخصوصية",
    "Terms of service": "شروط الخدمة",
    "Technical services": "الخدمات الفنية",
    "Contact this in case of a problem in the website": "اتصل بهذا في حالة وجود مشكلة في الموقع",
    "Commercial services": "الخدمات التجارية",
    "Contact this in case you have any questions": "اتصل بهذا إذا كان لديك أي أسئلة",
    "Domain": "النطاق",
    "Add store": "إضافة متجر",
    "Delete store": "حذف متجر",
    "Are you sure you want to delete this store ?": "هل أنت متأكد أنك تريد حذف هذا المتجر؟",
    "Please rewrite this text below: ": "يرجى إعادة كتابة هذا النص أدناه: ",
    "Cancel": "إلغاء",
    "Confirm": "تأكيد",
    "Your email is verified": "تم التحقق من بريدك الإلكتروني",
    "Your email is not verified": "لم يتم التحقق من بريدك الإلكتروني",
    "Setup your email": "إعداد بريدك الإلكتروني",
    "State:": "الولاية:",
    "Verified": "تم التحقق",
    "Not verified": "لم يتم التحقق",
    "To verify your email click ": "للتحقق من بريدك الإلكتروني، انقر ",
    "HERE": "هنا",
    "Change your password": "تغيير كلمة المرور",
    "Current password": "كلمة المرور الحالية",
    "New password": "كلمة المرور الجديدة",
    "Your password must have at least 8 characters": "يجب أن تحتوي كلمة المرور على 8 أحرف على الأقل",
    "Update": "تحديث",
    "Setup your phone number": "إعداد رقم هاتفك",
    "Gallery was not created": "لم يتم إنشاء المعرض",
    "Add Category": "إضافة فئة",
    "Save category": "حفظ الفئة",
    "Saving...": "جاري الحفظ...",
    "Error loading categories.": "خطأ في تحميل الفئات.",
    "You have no categories yet": "ليس لديك أي فئات بعد",
    "Add categories": "إضافة فئات",
    "Name : Color, Size, Material...": "الاسم: اللون، الحجم، المادة...",
    "Add variant": "إضافة متغير",
    "Discount": "خصم",
    "Discount: 25% off or -500DA ": "خصم: 25% أو -500 دج",
    "Your title must be at least 3 latters long": "يجب أن يكون العنوان مكون من 3 أحرف على الأقل",
    "Title": "العنوان",
    "Slug": "الرابط",
    "Mini description": "وصف مختصر",
    "Price": "السعر",
    "Original price": "السعر الأصلي",
    "These prices will not appear in your product page because you are using variants now": "لن تظهر هذه الأسعار في صفحة المنتج لأنك تستخدم المتغيرات الآن",
    "Product Details": "تفاصيل المنتج",
    "Related products": "المنتجات ذات الصلة",
    "All products are related": "جميع المنتجات ذات صلة",
    " products are selected": "تم تحديد المنتجات",
    "No product is related": "لا يوجد منتج ذو صلة",
    "Select all last 12 products": "تحديد آخر 12 منتج",
    "Click to add products": "انقر لإضافة المنتجات",
    "No product was found": "لم يتم العثور على أي منتج",
    "Description": "الوصف",
    "Use default shipping cost": "استخدام تكلفة الشحن الافتراضية",
    "Your image was not added": "لم يتم إضافة صورتك",
    "All images were not deleted": "لم يتم حذف جميع الصور",
    "Images": "الصور",
    "Add Images": "إضافة صور",
    "Delete all": "حذف الكل",
    "Are you sure you want to delete all images ?": "هل أنت متأكد أنك تريد حذف جميع الصور؟",
    "Origin price": "السعر الأصلي",
    "SKU": "SKU",
    "Varinats": "المتغيرات",
    "There was a problem": "حدثت مشكلة",
    "Error setting up your conversions api token": "خطأ في إعداد conversions api token",
    "Error while removing your facebook pixel, try again": "خطأ أثناء إزالة بكسل فيسبوك، حاول مرة أخرى",
    "Test event code": "رمز حدث الاختبار",
    "If you are not testing keep this field empty": "إذا لم تكن تختبر، اترك هذا الحقل فارغًا",
    "Set token": "تعيين الرمز",
    "Add facebook conversions api to your store to improve our conversion rate.": "أضف conversions api token إلى متجرك لتحسين معدل التحويل لدينا.",
    "Add ": "إضافة ",
    "Error setting up your events api token": "خطأ",
    "Error while removing your tiktok pixel, try again": "خطأ أثناء إزالة بكسل تيك توك، حاول مرة أخرى",
    "Error setting up your facebook pixel": "خطأ في إعداد بكسل فيسبوك",
    "Add facebook pixel to your store to improve our conversion rate from your facebook ad account": "أضف بكسل فيسبوك إلى متجرك لتحسين معدل التحويل لدينا من حسابك الإعلاني على فيسبوك",
    "Setup your facebook pixel": "إعداد بكسل فيسبوك",
    "Insert orders at the top": "إدراج الطلبات في الأعلى",
    "Insert orders at the bottom": "إدراج الطلبات في الأسفل",
    "Error while removing your google sheets account, try again": "خطأ أثناء إزالة حساب جوجل شيتس، حاول مرة أخرى",
    "This account is unactive because it is out of your plan, it will be autmatically activated when you upgrade your plan.": "هذا الحساب غير نشط لأنه خارج خطتك، سيتم تفعيله تلقائيًا عند ترقية خطتك.",
    "Click here to upgare your plan": "انقر هنا لترقية خطتك",
    "App script url": "رابط نص التطبيق",
    "Sheet name": "اسم الورقة",
    "Add sheet": "إضافة ورقة",
    "You do not have Messaging apps in your plan": "لا يوجد لديك تطبيقات مراسلة في خطتك",
    "Message": "الرسالة",
    "Enable what's app": "تفعيل واتساب",
    "Enable viber": "تفعيل فايبر",
    "Enable telegram": "تفعيل تليجرام",
    "Error setting up your tiktok pixel": "خطأ في إعداد بكسل تيك توك",
    "Add tiktok pixel to your store to improve our conversion rate from your tiktok ad account": "أضف بكسل تيك توك إلى متجرك لتحسين معدل التحويل لدينا من حسابك الإعلاني على تيك توك",
    "Setup your tiktok pixel": "إعداد بكسل تيك توك",
    "Order updated successfully": "تم تحديث الطلب بنجاح",
    "Order created successfully": "تم إنشاء الطلب بنجاح",
    "Cart Info": "معلومات السلة",
    "No product": "لا يوجد منتج",
    "Client full name": "الاسم الكامل للعميل",
    "Client phone": "هاتف العميل",
    "States": "الولايات",
    "City": "المدينة",
    "Shipping to": "الشحن إلى",
    "Client Note": "ملاحظة العميل",
    "Seller Note": "ملاحظة البائع",
    "Shipping Cost": "تكلفة الشحن",
    "Total price:": "السعر الإجمالي:",
    "Update order": "تحديث الطلب",
    "Create order": "إنشاء طلب",
    "Product Info": "معلومات المنتج",
    "Select your shipping city": "اختر مدينة الشحن",
    "Client full name, phone number, product name or order ID": "الاسم الكامل للعميل، رقم الهاتف، اسم المنتج أو معرف الطلب",
    "1 day": "يوم واحد",
    "7 days": "7 أيام",
    "1 month": "شهر واحد",
    "1 year": "سنة واحدة",
    "Orders per page:": "الطلبات لكل صفحة:",
    "Update paramters": "تحديث المعلمات",
    "All": "الكل",
    "Shipping feedback": "تعليقات الشحن",
    "Sent": "تم الإرسال",
    "Not sent": "لم يتم الإرسال",
    "Details": "التفاصيل",
    "Reasons": "الأسباب",
    "Select": "تحديد",
    "Delete selected": "حذف المحدد",
    "Update status": "تحديث الحالة",
    "Download as CSV": "تنزيل كملف CSV",
    "ID": "المعرف",
    "Client name": "اسم العميل",
    "Product title": "عنوان المنتج",
    "Cart": "السلة",
    "Ordering date": "تاريخ الطلب",
    "Shipping state": "ولاية الشحن",
    "Shipping city": "مدينة الشحن",
    "The orders you selected were not deleted": "لم يتم حذف الطلبات التي حددتها",
    "Error while updating statuses": "خطأ أثناء تحديث الحالات",
    "What do you want to add": "ماذا تريد إضافة؟",
    "Add order": "إضافة طلب",
    "Add cart": "إضافة سلة",
    "If you want to see new orders in real time click here": "إذا كنت تريد رؤية الطلبات الجديدة في الوقت الفعلي، انقر هنا",
    "New orders will appear here in real time. you do not have to realod the page": "ستظهر الطلبات الجديدة هنا في الوقت الفعلي. لا تحتاج إلى إعادة تحميل الصفحة",
    "You do not have any orders yet": "ليس لديك أي طلبات بعد",
    "You settings were saved": "تم حفظ إعداداتك",
    "You settings were not saved": "لم يتم حفظ إعداداتك",
    "Shipping ": "الشحن ",
    "Name": "الاسم",
    "Company URL": "رابط الشركة",
    "Fragile": "هش",
    "Can open": "يمكن فتحه",
    "Weight (Kg)": "الوزن (كجم)",
    "Add": "إضافة",
    "Ecotrack": "Ecotrack",
    "Add an account": "إضافة حساب",
    "Economic": "اقتصادي",
    "Express": "سريع",
    "Activate insurance": "تفعيل التأمين",
    "Height (Cm)": "الارتفاع (سم)",
    "Width (Cm)": "العرض (سم)",
    "Length (Cm)": "الطول (سم)",
    "Guepex": "Guepex",
    "Maystro": "Maystro",
    "Noest": "Noest",
    "Key": "المفتاح",
    "ProColis": "ProColis",
    "Yalidine": "Yalidine",
    "ZR Express": "ZR Express",
    "Always light": "دائمًا فاتح",
    "Always dark": "دائمًا غامق",
    "Let client decide": "دع العميل يقرر",
    "Copy link": "نسخ الرابط",
    "View category": "عرض الفئة",
    "Are you sure you want to delete this category?": "هل أنت متأكد أنك تريد حذف هذه الفئة؟",
    "Category was not deleted": "لم يتم حذف الفئة",
    "All products": "جميع المنتجات",
    "Footer": "قاع الصفحة",
    "Checkout": "الدفع",
    "You do not have the option to customize your domain in your plan.": "لا يوجد لديك خيار لتخصيص النطاق في خطتك.",
    "Click here to upgrade your plan": "انقر هنا لترقية خطتك",
    "Setup a custom domain": "إعداد نطاق مخصص",
    "Active": "نشط",
    "Not active": "غير نشط",
    "Note": "ملاحظة",
    "you will soon be contacted by phone to setup you domain. if your phone number is not registred yet please register your phone number": "سيتم الاتصال بك قريبًا عبر الهاتف لإعداد نطاقك. إذا لم يكن رقم هاتفك مسجلًا بعد، يرجى تسجيل رقم هاتفك",
    "here": "هنا",
    "Borders:": "الحدود:",
    "Sharp": "حاد",
    "Rounded": "مستدير",
    "Top text": "النص العلوي",
    "Background color": "لون الخلفية",
    "Header type:": "نوع الرأس:",
    "Filled": "مملوء",
    "Outlined": "محدد",
    "Primary color": "اللون الأساسي",
    "Text color": "لون النص",
    "Primary dark color": "اللون الأساسي الغامق",
    "Dark Text color": "لون النص الغامق",
    "Dark Background color": "لون الخلفية الغامق",
    "Logo": "الشعار",
    "Edit favicon": "تعديل الأيقونة",
    "New tab": "علامة تبويب جديدة",
    "Set up your socials": "إعداد وسائل التواصل الاجتماعي الخاصة بك",
    "Home page": "الصفحة الرئيسية",
    "Language": "اللغة",
    "Night mode": "الوضع الليلي",
    "Enable cart": "تفعيل السلة",
    "Enable search": "تفعيل البحث",
    "Hide hanotify logo": "إخفاء شعار Hanotify",
    "A client can make": "يمكن للعميل عمل",
    "Orders every": "طلب كل",
    "Hours": "ساعات",
    "Link": "الرابط",
    "Menu": "القائمة",
    "Add menu item": "إضافة عنصر قائمة",
    "Set paragraph": "تعيين فقرة",
    "Set navigation": "تعيين التنقل",
    "Show your socials": "عرض وسائل التواصل الاجتماعي الخاصة بك",
    "Set copyrights text": "تعيين نص حقوق النشر",
    "Breaking point": "نقطة التوقف",
    "Px": "بكسل",
    "Privacy policy was updated successfully": "تم تحديث سياسة الخصوصية بنجاح",
    "Error while updating the Privacy policy": "خطأ أثناء تحديث سياسة الخصوصية",
    "Customize your Privacy policy": "تخصيص سياسة الخصوصية الخاصة بك",
    "Terms of service was updated successfully": "تم تحديث شروط الخدمة بنجاح",
    "Error while updating the Terms of service": "خطأ أثناء تحديث شروط الخدمة",
    "Customize your Terms of service": "تخصيص شروط الخدمة الخاصة بك",
    "Thank you page was updated successfully": "تم تحديث صفحة الشكر بنجاح",
    "Error while updating the thank you page": "خطأ أثناء تحديث صفحة الشكر",
    "Customize your thank you page": "تخصيص صفحة الشكر الخاصة بك",
    "Show order informations": "عرض معلومات الطلب",
    "Client will see order information which includes:": "سيرى العميل معلومات الطلب التي تشمل:",
    "Product image": "صورة المنتج",
    "Order variants": "متغيرات الطلب",
    "Shipping price": "سعر الشحن",
    "Customized message": "رسالة مخصصة",
    "Show related products": "عرض المنتجات ذات الصلة",
    "This section will change according to the product settings": "سيتم تغيير هذا القسم وفقًا لإعدادات المنتج",
    "Button label": "تسمية الزر",
    "Image banner": "بانر الصورة",
    "Give customers details about the banner image or content on the template": "أعط العملاء تفاصيل عن صورة البانر أو المحتوى على القالب",
    "Add a message or any updated on your website using rich text": "أضف رسالة أو أي تحديث على موقعك باستخدام النص المنسق",
    "Image with text": "صورة مع نص",
    "Featured category": "فئة",
    "Add a title for this section": "إضافة عنوان لهذا القسم",
    "Slide show": "عرض الشرائح",
    "Tell your brand's story through images": "احكي قصة علامتك التجارية من خلال الصور",
    "Image with button": "صورة مع زر",
    "Rich text": "نص منسق",
    "Customers review": "مراجعة العملاء",
    "Images with link": "صور مع رابط",
    "Add section": "إضافة قسم",
    "Your home page is saved": "تم حفظ صفحتك الرئيسية",
    "Your home page is not saved": "لم يتم حفظ صفحتك الرئيسية",
    "Margin top": "الهامش العلوي",
    "Margin bottom": "الهامش السفلي",
    "Add review": "إضافة مراجعة",
    "Select a category": "اختر فئة",
    "Selected category": "الفئة المحددة",
    "More category": "المزيد من الفئات",
    "Show latest products": "عرض أحدث المنتجات",
    "Products per row for mobile": "المنتجات لكل صف للجوال",
    "Products per row for PC": "المنتجات لكل صف للكمبيوتر",
    "Number of products": "عدد المنتجات",
    "Image postion": "موضع الصورة",
    "Cover": "تغطية",
    "Contain": "احتواء",
    "Image aspect ratio": "نسبة العرض إلى الارتفاع للصورة",
    "Header": "الرأس",
    "Paragraph": "فقرة",
    "Button": "زر",
    "Mobile image": "صورة الجوال",
    "recommended size": "الحجم الموصى به",
    "Computer image": "صورة الكمبيوتر",
    "Image position": "موضع الصورة",
    "Image opacity": "شفافية الصورة",
    "Container opacity": "شفافية الحاوية",
    "Button horizontal position": "الموضع الأفقي للزر",
    "Content horizontal position": "الموضع الأفقي للمحتوى",
    "Start": "بداية",
    "Center": "الوسط",
    "End": "النهاية",
    "Button vertical position": "الموضع العمودي للزر",
    "Content vertical position": "الموضع العمودي للمحتوى",
    "Top": "أعلى",
    "Middle": "الوسط",
    "Bottom": "أسفل",
    "Images per row mobile": "الصور لكل صف للجوال",
    "Images per row PC": "الصور لكل صف للكمبيوتر",
    "Image shape": "شكل الصورة",
    "Add image with link": "إضافة صورة مع رابط",
    "Slide period": "فترة الشريحة",
    "Add slide": "إضافة شريحة",
    "Image": "الصورة",
    "Rating": "التقييم",
    "Content horizontal postion": "الموضع الأفقي للمحتوى",
    "Content vertical postion": "الموضع العمودي للمحتوى",
    "Obtenir votre e-store dans hanotify": "احصل على متجرك الإلكتروني في Hanotify",
    "Are you sure you want to copy this value \"{cost}\" to all home shipping cost fields ?": "هل أنت متأكد أنك تريد نسخ هذه القيمة \"{cost}\" إلى جميع حقول تكلفة الشحن إلى المنزل؟",
    "Are you sure you want to copy this value \"{cost}\" to all office shipping cost ?": "هل أنت متأكد أنك تريد نسخ هذه القيمة \"{cost}\" إلى جميع حقول تكلفة الشحن إلى المكتب؟",
    "Visitor \"{vistorId}\" is blocked": "الزائر \"{tracker}\" محظور",
    'Visitor "{vistorId}" is not blocked anymore':'لم يعد الزائر "{vistorId}" محظورًا بعد الآن',
    "Ship with {account}": "الشحن مع {account}",
    "Visitor {id}": "الزائر {id}",
    "To pay using {method} please send the money to the creditials below": "للدفع باستخدام {method}، يرجى إرسال المال إلى التفاصيل أدناه",
    "{months} Months": "{months} أشهر",
    "Are you sure you want to copy this value \"{price}\" to all prices fields ?": "هل أنت متأكد أنك تريد نسخ هذه القيمة \"{price}\" إلى جميع حقول الأسعار؟",
    "Are you sure you want to copy this value \"{price}\" to all original prices fields ?": "هل أنت متأكد أنك تريد نسخ هذه القيمة \"{price}\" إلى جميع حقول الأسعار الأصلية؟",
    "Are you sure you want to copy this value \"{sku}\" to all SKU fields ?": "هل أنت متأكد أنك تريد نسخ هذه القيمة \"{sku}\" إلى جميع حقول SKU؟",
    "Send to {name}": "إرسال إلى {name}",
    "Order {order}: ": "الطلب {order}: ",
    "Account {num}": "الحساب {num}",
    "Add menu item to \"{name}\"": "إضافة عنصر قائمة إلى \"{name}\"",
    "If the screen of the client device is smaller than {bp} pixels your menu content will appear on the sidebar, and if it is bigger than {bp} pixels the menu content will appear in the header.": "إذا كانت شاشة جهاز العميل أصغر من {bp} بكسل، فسيظهر محتوى القائمة في الشريط الجانبي، وإذا كانت أكبر من {bp} بكسل، فسيظهر محتوى القائمة في الرأس.",
    "Image {count}": "الصورة {count}",
    "Customers reviews": "مراجعات العملاء",
    "Product {count}": "المنتج {count}",
    "Review {count}": "المراجعة {count}",
    "Slide {count}": "الشريحة {count}",
    'Organic': 'بلا إعلان',
    'organic': 'بلا إعلان',
    "Abandoned orders": "الطلبات المتروكة"  ,
    "{days} days left": "{days} يوم المتبقية",
    "Ecotrack / DHD / Rocket and many others":  "Ecotrack / DHD / Rocket وغيرها الكثير",
    "Are you sure you want to leave?": "هل أنت متأكد من أنك تريد المغادرة؟",
    "Images over 200KB will be optimized": "سيتم تحسين الصور التي يزيد حجمها عن 200 كيلو بايت",
    "Loud": "صوت",
    "Silence": "صامت",
    "This category has no products": 'هذه الفئة لا يوجد بها منتجات',
    "Android app": "تطبيق أندرويد",
    "IPhone app": "تطبيق ايفون",
    "Soon": "قريباً",
    "Pricing": 'الأسعار',
    "You can only send one order at a time because Maystro still uses 48 states system": 'يمكنك إرسال طلب واحد فقط في كل مرة لأن Maystro لا يزال يستخدم نظام 48 ولاية',
    "Text button": "زر نصي",
    "Radio button": "زر اختيار",
    "Drop down": "قائمة منسدلة",
    "Colored square": "مربع ملون",
    "Image with text": "صورة مع نص",
    "Put 0 for free shipping and empty space if there was no shipping": "ضع 0 للشحن المجاني و أترك مساحة فارغة إذا لم يكن هناك شحن",
    "Watch tutorial": "مشاهدة الفديو التعليمي",
    "Regarder": "فديو تعليمي",
    "Google sheet is set successfully": "تم تعيين Google sheets بنجاح",
    "Full name and username": "الاسم الكامل واسم المستخدم",
    "Your full name must be at least 3 latters long": "يجب أن يكون اسمك الكامل مكونًا من 3 أحرف على الأقل",
    "Your email is not valid": "بريدك الإلكتروني غير صالح",
    "Register now": "سجل الآن",
    'Upload images from iphone directly no need to convert .HEIC / .HEIV images': "قم بتحميل الصور مباشرة من iPhone دون الحاجة إلى تحويل صور .HEIC / .HEIV",
    "Images load very fast because of our optimization algorithm": "يتم تحميل الصور بسرعة كبيرة بفضل خوارزمية تحسين الصور لدينا",
    "Page builder for customized stores": "منشئ صفحات ليكون لك متجر مختلف عن البقية",
    "Send all your orders with one click": "أرسل جميع طلباتك بنقرة واحدة",
    "The best fake orders blocking system": "أفضل نظام لحظر الطلبات الوهمية",
    "Get notifications both in mobile app and browser": "احصل على الإشعارات في تطبيق الهاتف والمتصفح",
    "Create multiple stores with one account": "أنشئ عدة متاجر بحساب واحد",
    "Supported languages are arabic, french and english": "اللغات المدعومة هي العربية والفرنسية والإنجليزية",
    "Learn more": "تعلم المزيد",
    'Select currencies': 'اختر العملات',
    'Payment bank': 'بنك الدفع',
    'Payment amount': 'مبلغ الدفع',
    'Admin note': 'ملاحظة المسؤول',
    'Payment image': 'صورة الدفع',
    'No payments yet': 'لا توجد مدفوعات بعد',
    'You do not have an affiliation link yet': 'ليس لديك رابط إحالة حتى الآن',
    'Create your affiliation link': 'أنشئ رابط الإحالة الخاص بك',
    'Affiliate program': 'برنامج الإحالة',
    'Total money earned': 'إجمالي الأموال المكتسبة',
    'Total money withdrawed': 'إجمالي الأموال المسحوبة',
    'Unwithdrawed money': 'الأموال غير المسحوبة',
    'Referral link': 'رابط الإحالة',
    'Withdraw methods': 'طرق السحب',
    'Please select the payment methods you want to get your money with': 'يرجى اختيار طرق الدفع التي ترغب في استلام أموالك بها',
    'When your unwithdrawed money exceeds 4000 DZ you can ask for a checkout': 'عندما تتجاوز أموالك غير المسحوبة 4000 دينار جزائري، يمكنك طلب السحب',
    'Withdraw your money': 'اسحب أموالك',
    'You will recieve your payement is the next 24 to 48 hours, you will get an email when we do': 'سوف تتلقى دفعتك خلال الـ 24 إلى 48 ساعة القادمة، وستتلقى بريدًا إلكترونيًا عند القيام بذلك',
    'Show conditions': 'عرض الشروط',
    'Show payments history': 'عرض سجل المدفوعات',
    'How does it work ?': 'كيف يعمل؟',
    'When a new user registers with your affiliation link you get a commission each time he makes a payment, and he will get a discount on his first purchase': 'عندما يسجل مستخدم جديد عبر رابط الإحالة الخاص بك، ستحصل على عمولة في كل مرة يقوم فيها بالدفع، كما سيحصل المستخدم الجديد على خصم على أول عملية شراء له',
    'The affiliate commission': 'عمولة الإحالة',
    '30% of the new user first payment no matter how much he paid': '30% من أول دفعة للمستخدم الجديد بغض النظر عن المبلغ المدفوع',
    '20% of the new user second payment no matter how much he paid': '20% من الدفعة الثانية للمستخدم الجديدبغض النظر عن المبلغ المدفوع',
    '10% of the new user third payment no matter how much he paid': '10% من المستخدم الجديد دفعة ثالثةبغض النظر عن المبلغ المدفوع',
    '10% of the new user th payment no matter how much he paid': '30٪ من أول دفعة للمستخدم الجديد بغض النظر عن المبلغ المدفوع',
    '5% of all the new user payments starting from the forth payemnt no matter how much he paid': '5٪ من جميع مدفوعات المستخدم الجديد بدءًا من الدفعة الرابعة بغض النظر عن المبلغ المدفوع',
    'The new user discount': 'خصم المستخدم الجديد',
    'New user get an extra 10% discount on his first plan no matter how much that plan costs': 'يحصل المستخدم الجديد على خصم إضافي بنسبة 10٪ على خطته الأولى بغض النظر عن تكلفتها',
    'Important': "مهم",
    'Username or email': "اسم المستخدم أو البريد الإلكتروني",
    "Watch our introduction video": "شاهد الفيديو التقديمي الخاص بك",
    "Download our SMS app (Android only)": "قم بتنزيل تطبيق الرسائل القصيرة (Android فقط)",
    "Affiliate": 'التسويق بالعمولة',
    'Since you have been referraled by "{name}" you get an extra 10% discount on your first plan': 'بما أنه تمت إحالتك بواسطة "{name}"، فإنك تحصل على خصم إضافي بنسبة 10% على خطتك الأولى',
    'Send SMS to clients': 'إرسال رسائل نصية للعملاء',
    'Send an sms to your clients when they make an order so they feel committed to your product and not cancel': 'أرسل رسالة نصية لعملائك عند تقديم طلب حتى يشعروا بالالتزام تجاه منتجك ولا يلغوه',
    'Send automatic sms to clients after ordering': 'إرسال رسائل نصية تلقائية للعملاء بعد الطلب',
    "Last 24 hours abandoned orders": "آخر 24 ساعة طلب متروك",
    "Watchs store": "متجر الساعات",
    "Clothes shop": "محل ملابس",
    "For each store you can select the message you want to send the client when makes an order.": "لكل متجر، يمكنك اختيار الرسالة التي ترغب في إرسالها إلى العميل عند تقديم طلب.",
    "When a client makes an order our app will recieve that order and sends an sms with you default sim card.": "عند قيام العميل بتقديم طلب، ستتلقى تطبيقنا هذا الطلب وترسل رسالة نصية عبر بطاقة SIM الافتراضية الخاصة بك.",
    "After installing the app, login using your hanotify creditials. give the app the permission and you will see your stores.": "بعد تثبيت التطبيق، قم بتسجيل الدخول باستخدام بيانات اعتماد Hanotify. امنح التطبيق الأذونات وستتمكن من رؤية متاجرك.",
    "In case your phone was offline for a while and you recieved orders when your phone was offline, when you connect to the intenet your phone will still send sms messages to those clients, you can deactivate this in the app": "في حال كان هاتفك غير متصل لفترة وتلقيت طلبات خلال هذا الوقت، عند إعادة الاتصال بالإنترنت، سيظل هاتفك يرسل رسائل نصية إلى هؤلاء العملاء. يمكنك تعطيل هذا الخيار في التطبيق.",
    "Always keep your phone on and connected to the internet": "احرص دائمًا على إبقاء هاتفك قيد التشغيل ومتصلًا بالإنترنت.",
    "When the app is installed, give it a permission to send sms and a permission to always work on the background": "عند تثبيت التطبيق، امنحه إذنًا لإرسال الرسائل النصية وإذنًا للعمل دائمًا في الخلفية.",
    "Setup": "الخطوات",
    "Store Exemples": "أمثلة للمتجر",
    "Open link in an external page": "فتح الرابط في صفحة خارجية",
    'Customize form': 'تخصيص الفورم',
    'Form title': 'عنوان الفورم',
    '↓Fill the form below to order↓': '↓للطلب املئ المعلومات في الأسفل↓',
    'Add note': 'إضافة ملاحظة',
    'Full name field': 'حقل الاسم الكامل',
    'Phone number field': 'حقل رقم الهاتف',
    'Email field': 'حقل البريد الإلكتروني',
    'Address field': 'حقل العنوان',
    'City field': 'حقل المدينة',
    'State field': 'حقل الولاية',
    'Client note field': 'حقل ملاحظة العميل',
    'Buy button': 'زر الشراء',
    'Buy now': 'اشتر الآن',
    'Required': 'مطلوب', 
    'Add credit to your account here': 'أضف رصيدًا إلى حسابك من هنا',
    'Form settings': 'إعدادات الفورم',
    "When you change your password. all devices using your account will be logged out including this device": "عندما تقوم بتغيير كلمة المرور الخاصة بك. سيتم تسجيل الخروج من جميع الأجهزة التي تستخدم حسابك بما في ذلك هذا الجهاز",
    "You have been logged out": "لقد تم تسجيل خروجك.",
    'Now send a screenshot of the transaction': "أرسل الآن لقطة شاشة لعملية الدفع",
  },

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  'fr': {
    "Home": "Accueil",
    "apply to all": "appliquer à tous",
    "Office": "Bureau",
    "Search a state": "Rechercher un état",
    "Image was not uploaded because its size is too large (over 10MB)": "L'image n'a pas été téléchargée car sa taille est trop grande (plus de 10 Mo)",
    "Image was not uploaded": "L'image n'a pas été téléchargée",
    "DA": "DA",
    "User": "Utilisateur",
    "Account settings": "Paramètres du compte",
    "Your stores": "Vos magasins",
    "Dashboard": "Tableau de bord",
    "Orders": "Commandes",
    "Obandened orders": "Commandes abandonnées",
    "Archived orders": "Commandes archivées",
    "Products": "Produits",
    "Your products": "Votre produits",
    "Add product": "Ajouter un produit",
    "Store": "Magasin",
    "Settings": "Paramètres",
    "General design": "Conception générale",
    "Pages": "Pages",
    "Navigation": "Navigation",
    "Categories": "Catégories",
    "Socials": "Réseaux sociaux",
    "Customize domain": "Personnaliser le domaine",
    "Apps": "Applications",
    "Statistics": "Statistiques",
    "Payment": "Paiement",
    "Plans": "Plans",
    "Support": "Support",
    "Download Android app": "L'application Android",
    "Logout": "Déconnexion",
    "Logout Failed": "Échec de la déconnexion",
    "First name, last name and username": "Prénom, nom et nom d'utilisateur",
    "Password": "Mot de passe",
    "Phone number": "Numéro de téléphone",
    "Your product was not listed": "Votre produit n'a pas été listé",
    "Infomation": "Information",
    "Variants": "Variantes",
    "Shipping": "Livraison",
    "Save": "Enregistrer",
    "Google Sheets": "Google Sheets",
    "Google Sheets is a spreadsheet app used to organize, format, and calculate your orders.": "Google Sheets est une application de feuille de calcul utilisée pour organiser, formater et calculer vos commandes.",
    "Facebook pixel": "Pixel Facebook",
    "Add facebok pixel to your store to improve our conversion rate.": "Ajoutez un pixel Facebook à votre magasin pour améliorer notre taux de conversion.",
    "Facebook Conversions API": "API de conversions Facebook",
    "Add facebook conversions api to your store to improve our conversion rate.": "Ajoutez l'API de conversions Facebook à votre magasin pour améliorer notre taux de conversion.",
    "Tiktok pixels": "Pixels TikTok",
    "Add tiktok pixel to your store to improve our conversion rate from your tiktok ad account.": "Ajoutez un pixel TikTok à votre magasin pour améliorer notre taux de conversion depuis votre compte publicitaire TikTok.",
    "Tiktok Events API": "API d'événements TikTok",
    "Add tiktok events api to your store to improve our conversion rate.": "Ajoutez l'API d'événements TikTok à votre magasin pour améliorer notre taux de conversion.",
    "Messaging apps": "Applications de messagerie",
    "send confirmation messages to messaging apps when someone makes an order to increase confirmed orders": "envoyez des messages de confirmation aux applications de messagerie lorsque quelqu'un passe une commande pour augmenter les commandes confirmées",
    "Welcome to the dashboard!": "Bienvenue sur le tableau de bord !",
    "As a new user you have 15 days of the Advanced plan, Enjoy": "En tant que nouvel utilisateur, vous bénéficiez de 15 jours du plan Advanced, profitez-en !",
    "Your current store domain is": "Le domaine actuel de votre magasin est",
    "Change domain": "Changer de domaine",
    "Setup your account": "Configurez votre compte",
    "Last 24 hours orders": "Commandes des dernières 24 heures",
    "Last 24 hours": "Dernières 24 heures",
    "Last 24 hours abandoned orders": "Commande abandonnée au cours des dernières 24 heures",
    "Last week orders": "Commandes de la semaine dernière",
    "Last month orders": "Commandes du mois dernier",
    "All time orders": "Commandes de tous les temps",
    "Last 24 hours obendended orders": "Commandes abandonnées des dernières 24 heures",
    "Last 24 hours confirmed orders": "Commandes confirmées des dernières 24 heures",
    "Last 24 hours cancelled orders": "Commandes annulées des dernières 24 heures",
    "Login": "Connexion",
    "Register": "S'inscrire",
    "Set-up your store in just few clicks": "Configurez votre magasin en quelques clics",
    "Get started now": "Commencez maintenant",
    "Hanotify": "Hanotify",
    "Login to your account at Hanotify.": "Connectez-vous à votre compte sur Hanotify.",
    "Go back home page": "Retour à la page d'accueil",
    "Login to your account at Hanotify": "Connectez-vous à votre compte sur Hanotify",
    "Username": "Nom d'utilisateur",
    "Did you forget your password ?": "Avez-vous oublié votre mot de passe ?",
    "You do not have an account?": "Vous n'avez pas de compte ?",
    "Product": "Produit",
    "Number": "Numéro",
    "Status": "Statut",
    "Total": "Total",
    "Full name": "Nom complet",
    "State": "État",
    "Commune": "Commune",
    "Qnty": "Qnté",
    "Date": "Date",
    "Ad": "Publicité",
    "Erro while getting the phone number": "Erreur lors de la récupération du numéro de téléphone",
    "Edit": "Modifier",
    "Delete": "Supprimer",
    "Bump": "Remonter",
    "Unobandon": "Ne pas abandonner",
    "Are you sure you want to delete this option?": "Êtes-vous sûr de vouloir supprimer cette option ?",
    "Order id": "ID de commande",
    "Product cost": "Coût du produit",
    "Quantity": "Quantité",
    "Ad comapany": "Société de publicité",
    "Shipping cost": "Frais de livraison",
    "Total price": "Prix total",
    "Address": "Adresse",
    "Client note": "Note du client",
    "Seller note": "Note du vendeur",
    "Client": "Client",
    "IP Addresses": "Adresses IP",
    "Unblock visitor": "Débloquer le visiteur",
    "Block Visitor": "Bloquer le visiteur",
    "DZD": "DZD",
    "Add a note": "Ajouter une note",
    "Submit Payment": "Soumettre le paiement",
    "Choose a payment method": "Choisissez un mode de paiement",
    "Your plan": "Votre plan",
    "Exipation date": "Date d'expiration",
    "No expiration date": "Pas de date d'expiration",
    "Orders left": "Commandes restantes",
    "Infinite": "Infini",
    "Abendened Orders left": "Commandes abandonnées restantes",
    "Facebook pixels": "Pixels Facebook",
    "Conversions api token": "Token de l'API de conversions",
    "Tiktok events api token": "Token de l'API d'événements TikTok",
    "Google sheets accounts": "Comptes Google Sheets",
    "Send to shipping companies in bulk": "Envoyer aux compagnies de livraison en vrac",
    "Send orders with messaging apps(What's app, viber and telegram)": "Envoyer des commandes avec des applications de messagerie (WhatsApp, Viber et Telegram)",
    "Show the ad company of each order": "Afficher la société de publicité de chaque commande",
    "Hide hanotify logo from your store": "Masquer le logo Hanotify de votre magasin",
    "Custom domain": "Domaine personnalisé",
    "DZ": "DZ",
    "Month": "Mois",
    "Off": "Remise",
    "Buy now": "Acheter maintenant",
    "Exit": "Quitter",
    "month": "mois",
    "Abendened Orders": "Commandes abandonnées",
    "Conversions api tokens": "Tokens de l'API de conversions",
    "Tiktok events api tokens": "Tokens de l'API d'événements TikTok",
    "Google sheet accounts": "Comptes Google Sheet",
    "Send orders with messaging apps (What's app, viber and telegram)": "Envoyer des commandes avec des applications de messagerie (WhatsApp, Viber et Telegram)",
    "Extend your plan": "Prolonger votre plan",
    "Activate this plan": "Activer ce plan",
    "Our plans": "Nos plans",
    "Since this is your current plan you do not need buy this now it will be automaitcally reactived when the plan expires": "Comme il s'agit de votre plan actuel, vous n'avez pas besoin de l'acheter maintenant, il sera automatiquement réactivé à l'expiration du plan.",
    "Your product was deleted successfully": "Votre produit a été supprimé avec succès",
    "Your product was not deleted": "Votre produit n'a pas été supprimé",
    "Pieces": "Pièces",
    "Duplicate product": "Dupliquer le produit",
    "Product page": "Page du produit",
    "landing page": "Page de destination",
    "Deactivate": "Désactiver",
    "Activate": "Activer",
    "Are you sure you want to delete this product?": "Êtes-vous sûr de vouloir supprimer ce produit ?",
    "Are you sure you want to duplicate this product?": "Êtes-vous sûr de vouloir dupliquer ce produit ?",
    "Select all": "Tout sélectionner",
    "Are you sure you want to delete these products?": "Êtes-vous sûr de vouloir supprimer ces produits ?",
    "Reload": "Recharger",
    "Register an account at Hanotify": "Créer un compte sur Hanotify",
    "Your first name must be at least 3 latters long": "Votre prénom doit contenir au moins 3 lettres",
    "Your last name must be at least 3 latters long": "Votre nom doit contenir au moins 3 lettres",
    "Your password must be at least 8 latters long": "Votre mot de passe doit contenir au moins 8 lettres",
    "Your passwords do not macth": "Vos mots de passe ne correspondent pas",
    "First name": "Prénom",
    "Last name": "Nom",
    "Confirm password": "Confirmer le mot de passe",
    "this username is taken": "Ce nom d'utilisateur est déjà pris",
    "this username is all yours": "Ce nom d'utilisateur est à vous",
    "Next": "Suivant",
    "Go back": "Retour",
    "You already have an account?": "Vous avez déjà un compte ?",
    "Reset your password": "Réinitialiser votre mot de passe",
    "Please write the email of your account": "Veuillez écrire l'e-mail de votre compte",
    "Send password to email": "Envoyer le mot de passe par e-mail",
    "Go back to login page": "Retour à la page de connexion",
    "Shipping costs": "Frais de livraison",
    "Personlize your shipping cost according to the states": "Personnalisez vos frais de livraison en fonction des états",
    "Shipping Companies": "Compagnies de livraison",
    "Connect your store to other shipping companies": "Connectez votre magasin à d'autres compagnies de livraison",
    "Category": "Catégorie",
    "Thank you page": "Page de remerciement",
    "Privacy policy": "Politique de confidentialité",
    "Terms of service": "Conditions d'utilisation",
    "Technical services": "Services techniques",
    "Contact this in case of a problem in the website": "Contactez ce service en cas de problème sur le site web",
    "Commercial services": "Services commerciaux",
    "Contact this in case you have any questions": "Contactez ce service si vous avez des questions",
    "Domain": "Domaine",
    "Add store": "Ajouter un magasin",
    "Delete store": "Supprimer un magasin",
    "Are you sure you want to delete this store ?": "Êtes-vous sûr de vouloir supprimer ce magasin ?",
    "Please rewrite this text below: ": "Veuillez réécrire ce texte ci-dessous :",
    "Cancel": "Annuler",
    "Confirm": "Confirmer",
    "Your email is verified": "Votre e-mail est vérifié",
    "Your email is not verified": "Votre e-mail n'est pas vérifié",
    "Setup your email": "Configurez votre e-mail",
    "State:": "État :",
    "Verified": "Vérifié",
    "Not verified": "Non vérifié",
    "To verify your email click ": "Pour vérifier votre e-mail, cliquez ",
    "HERE": "ICI",
    "Change your password": "Changer votre mot de passe",
    "Current password": "Mot de passe actuel",
    "New password": "Nouveau mot de passe",
    "Your password must have at least 8 characters": "Votre mot de passe doit contenir au moins 8 caractères",
    "Update": "Mettre à jour",
    "Setup your phone number": "Configurez votre numéro de téléphone",
    "Gallery was not created": "La galerie n'a pas été créée",
    "Add Category": "Ajouter une catégorie",
    "Save category": "Enregistrer la catégorie",
    "Saving...": "Enregistrement...",
    "Error loading categories.": "Erreur lors du chargement des catégories.",
    "You have no categories yet": "Vous n'avez pas encore de catégories",
    "Add categories": "Ajouter des catégories",
    "Name : Color, Size, Material...": "Nom : Couleur, Taille, Matériau...",
    "Add variant": "Ajouter une variante",
    "Discount": "Réduction",
    "Discount: 25% off or -500DA ": "Réduction : 25% de réduction ou -500DA",
    "Your title must be at least 3 latters long": "Votre titre doit contenir au moins 3 lettres",
    "Title": "Titre",
    "Slug": "Slug",
    "Mini description": "Mini description",
    "Price": "Prix",
    "Original price": "Prix d'origine",
    "These prices will not appear in your product page because you are using variants now": "Ces prix n'apparaîtront pas sur votre page produit car vous utilisez désormais des variantes",
    "Product Details": "Détails du produit",
    "Related products": "Produits associés",
    "All products are related": "Tous les produits sont associés",
    " products are selected": "produits sont sélectionnés",
    "No product is related": "Aucun produit n'est associé",
    "Select all last 12 products": "Sélectionner les 12 derniers produits",
    "Click to add products": "Cliquez pour ajouter des produits",
    "No product was found": "Aucun produit n'a été trouvé",
    "Description": "Description",
    "Use default shipping cost": "Utiliser les frais de livraison par défaut",
    "Your image was not added": "Votre image n'a pas été ajoutée",
    "All images were not deleted": "Toutes les images n'ont pas été supprimées",
    "Images": "Images",
    "Add Images": "Ajouter des images",
    "Delete all": "Tout supprimer",
    "Are you sure you want to delete all images ?": "Êtes-vous sûr de vouloir supprimer toutes les images ?",
    "Origin price": "Prix d'origine",
    "SKU": "SKU",
    "Varinats": "Variantes",
    "There was a problem": "Il y a eu un problème",
    "Error setting up your conversions api token": "Erreur lors de la configuration de votre token d'API de conversions",
    "Error while removing your facebook pixel, try again": "Erreur lors de la suppression de votre pixel Facebook, réessayez",
    "Test event code": "Code d'événement de test",
    "If you are not testing keep this field empty": "Si vous ne testez pas, laissez ce champ vide",
    "Set token": "Définir le token",
    "Add facebook conversions api to your store to improve our conversion rate.": "Ajoutez l'API de conversions Facebook à votre magasin pour améliorer notre taux de conversion.",
    "Add ": "Ajouter ",
    "Error setting up your events api token": "Erreur lors de la configuration de votre token d'API d'événements",
    "Error while removing your tiktok pixel, try again": "Erreur lors de la suppression de votre pixel TikTok, réessayez",
    "Error setting up your facebook pixel": "Erreur lors de la configuration de votre pixel Facebook",
    "Add facebook pixel to your store to improve our conversion rate from your facebook ad account": "Ajoutez un pixel Facebook à votre magasin pour améliorer notre taux de conversion depuis votre compte publicitaire Facebook",
    "Setup your facebook pixel": "Configurez votre pixel Facebook",
    "Insert orders at the top": "Insérer les commandes en haut",
    "Insert orders at the bottom": "Insérer les commandes en bas",
    "Error while removing your google sheets account, try again": "Erreur lors de la suppression de votre compte Google Sheets, réessayez",
    "This account is unactive because it is out of your plan, it will be autmatically activated when you upgrade your plan.": "Ce compte est inactif car il dépasse votre plan, il sera automatiquement activé lorsque vous mettrez à niveau votre plan.",
    "Click here to upgare your plan": "Cliquez ici pour mettre à niveau votre plan",
    "App script url": "URL du script d'application",
    "Sheet name": "Nom de la feuille",
    "Add sheet": "Ajouter une feuille",
    "You do not have Messaging apps in your plan": "Vous n'avez pas d'applications de messagerie dans votre plan",
    "Message": "Message",
    "Enable what's app": "Activer WhatsApp",
    "Enable viber": "Activer Viber",
    "Enable telegram": "Activer Telegram",
    "Error setting up your tiktok pixel": "Erreur lors de la configuration de votre pixel TikTok",
    "Add tiktok pixel to your store to improve our conversion rate from your tiktok ad account": "Ajoutez un pixel TikTok à votre magasin pour améliorer notre taux de conversion depuis votre compte publicitaire TikTok",
    "Setup your tiktok pixel": "Configurez votre pixel TikTok",
    "Order updated successfully": "Commande mise à jour avec succès",
    "Order created successfully": "Commande créée avec succès",
    "Cart Info": "Informations du panier",
    "No product": "Aucun produit",
    "Client full name": "Nom complet du client",
    "Client phone": "Téléphone du client",
    "States": "États",
    "City": "Ville",
    "Shipping to": "Livraison à",
    "Client Note": "Note du client",
    "Seller Note": "Note du vendeur",
    "Shipping Cost": "Frais de livraison",
    "Total price:": "Prix total :",
    "Update order": "Mettre à jour la commande",
    "Create order": "Créer une commande",
    "Product Info": "Informations sur le produit",
    "Select your shipping city": "Sélectionnez votre ville de livraison",
    "Client full name, phone number, product name or order ID": "Nom complet du client, numéro de téléphone, nom du produit ou ID de commande",
    "1 day": "1 jour",
    "7 days": "7 jours",
    "1 month": "1 mois",
    "1 year": "1 an",
    "Orders per page:": "Commandes par page :",
    "Update paramters": "Mettre à jour les paramètres",
    "All": "Tous",
    "Shipping feedback": "Retour de livraison",
    "Sent": "Envoyé",
    "Not sent": "Non envoyé",
    "Details": "Détails",
    "Reasons": "Raisons",
    "Select": "Sélectionner",
    "Delete selected": "Supprimer la sélection",
    "Update status": "Mettre à jour le statut",
    "Download as CSV": "Télécharger en CSV",
    "ID": "ID",
    "Client name": "Nom du client",
    "Product title": "Titre du produit",
    "Cart": "Panier",
    "Ordering date": "Date de commande",
    "Shipping state": "État de livraison",
    "Shipping city": "Ville de livraison",
    "The orders you selected were not deleted": "Les commandes que vous avez sélectionnées n'ont pas été supprimées",
    "Error while updating statuses": "Erreur lors de la mise à jour des statuts",
    "What do you want to add": "Que voulez-vous ajouter ?",
    "Add order": "Ajouter une commande",
    "Add cart": "Ajouter un panier",
    "If you want to see new orders in real time click here": "Si vous souhaitez voir les nouvelles commandes en temps réel, cliquez ici",
    "New orders will appear here in real time. you do not have to realod the page": "Les nouvelles commandes apparaîtront ici en temps réel. Vous n'avez pas besoin de recharger la page",
    "You do not have any orders yet": "Vous n'avez encore aucune commande",
    "You settings were saved": "Vos paramètres ont été enregistrés",
    "You settings were not saved": "Vos paramètres n'ont pas été enregistrés",
    "Shipping ": "Livraison ",
    "Name": "Nom",
    "Company URL": "URL de l'entreprise",
    "Fragile": "Fragile",
    "Can open": "Peut ouvrir",
    "Weight (Kg)": "Poids (Kg)",
    "Add": "Ajouter",
    "Ecotrack": "Ecotrack",
    "Add an account": "Ajouter un compte",
    "Economic": "Économique",
    "Express": "Express",
    "Activate insurance": "Activer l'assurance",
    "Height (Cm)": "Hauteur (Cm)",
    "Width (Cm)": "Largeur (Cm)",
    "Length (Cm)": "Longueur (Cm)",
    "Guepex": "Guepex",
    "Maystro": "Maystro",
    "GUID": "GUID",
    "Noest": "Noest",
    "Key": "Clé",
    "ProColis": "ProColis",
    "Yalidine": "Yalidine",
    "ZR Express": "ZR Express",
    "Always light": "Toujours clair",
    "Always dark": "Toujours sombre",
    "Let client decide": "Laisser le client décider",
    "Copy link": "Copier le lien",
    "View category": "Voir la catégorie",
    "Are you sure you want to delete this category?": "Êtes-vous sûr de vouloir supprimer cette catégorie ?",
    "Category was not deleted": "La catégorie n'a pas été supprimée",
    "All products": "Tous les produits",
    "Footer": "Pied de page",
    "Checkout": "Commander",
    "You do not have the option to customize your domain in your plan.": "Vous n'avez pas la possibilité de personnaliser votre domaine dans votre plan.",
    "Click here to upgrade your plan": "Cliquez ici pour mettre à niveau votre plan",
    "Setup a custom domain": "Configurer un domaine personnalisé",
    "Active": "Actif",
    "Not active": "Non actif",
    "Note": "Note",
    "you will soon be contacted by phone to setup you domain. if your phone number is not registred yet please register your phone number": "Vous serez bientôt contacté par téléphone pour configurer votre domaine. Si votre numéro de téléphone n'est pas encore enregistré, veuillez l'enregistrer.",
    "here": "ici",
    "Borders:": "Bordures :",
    "Sharp": "Net",
    "Rounded": "Arrondi",
    "Top text": "Texte en haut",
    "Background color": "Couleur de fond",
    "Header type:": "Type d'en-tête :",
    "Filled": "Rempli",
    "Outlined": "Contour",
    "Primary color": "Couleur principale",
    "Text color": "Couleur du texte",
    "Primary dark color": "Couleur principale sombre",
    "Dark Text color": "Couleur du texte sombre",
    "Dark Background color": "Couleur de fond sombre",
    "Logo": "Logo",
    "Edit favicon": "Modifier le favicon",
    "New tab": "Nouvel onglet",
    "Set up your socials": "Configurez vos réseaux sociaux",
    "Home page": "Page d'accueil",
    "Language": "Langue",
    "Night mode": "Mode nuit",
    "Enable cart": "Activer le panier",
    "Enable search": "Activer la recherche",
    "Hide hanotify logo": "Masquer le logo Hanotify",
    "A client can make": "Un client peut faire",
    "Every": "Commande chaque",
    "Hours": "Heures",
    "Link": "Lien",
    "Menu": "Menu",
    "Add menu item": "Ajouter un élément de menu",
    "Set paragraph": "Définir un paragraphe",
    "Set navigation": "Définir la navigation",
    "Show your socials": "Afficher vos réseaux sociaux",
    "Set copyrights text": "Définir le texte des droits d'auteur",
    "Breaking point": "Point de rupture",
    "Px": "Px",
    "Privacy policy was updated successfully": "La politique de confidentialité a été mise à jour avec succès",
    "Error while updating the Privacy policy": "Erreur lors de la mise à jour de la politique de confidentialité",
    "Customize your Privacy policy": "Personnalisez votre politique de confidentialité",
    "Terms of service was updated successfully": "Les conditions d'utilisation ont été mises à jour avec succès",
    "Error while updating the Terms of service": "Erreur lors de la mise à jour des conditions d'utilisation",
    "Customize your Terms of service": "Personnalisez vos conditions d'utilisation",
    "Thank you page was updated successfully": "La page de remerciement a été mise à jour avec succès",
    "Error while updating the thank you page": "Erreur lors de la mise à jour de la page de remerciement",
    "Customize your thank you page": "Personnalisez votre page de remerciement",
    "Show order informations": "Afficher les informations de commande",
    "Client will see order information which includes:": "Le client verra les informations de commande qui incluent :",
    "Product image": "Image du produit",
    "Order variants": "Variantes de commande",
    "Shipping price": "Prix de livraison",
    "Customized message": "Message personnalisé",
    "Show related products": "Afficher les produits associés",
    "This section will change according to the product settings": "Cette section changera en fonction des paramètres du produit",
    "Button label": "Libellé du bouton",
    "Image banner": "Bannière d'image",
    "Give customers details about the banner image or content on the template": "Donnez aux clients des détails sur l'image de la bannière ou le contenu du modèle",
    "Add a message or any updated on your website using rich text": "Ajoutez un message ou toute mise à jour sur votre site web en utilisant du texte enrichi",
    "Image with text": "Image avec texte",
    "Featured category": "Catégorie en vedette",
    "Add a title for this section": "Ajouter un titre pour cette section",
    "Slide show": "Diaporama",
    "Tell your brand's story through images": "Racontez l'histoire de votre marque à travers des images",
    "Image with button": "Image avec bouton",
    "Rich text": "Texte enrichi",
    "Customers review": "Avis des clients",
    "Images with link": "Images avec lien",
    "Add section": "Ajouter une section",
    "Your home page is saved": "Votre page d'accueil est enregistrée",
    "Your home page is not saved": "Votre page d'accueil n'est pas enregistrée",
    "Margin top": "Marge en haut",
    "Margin bottom": "Marge en bas",
    "Add review": "Ajouter un avis",
    "Select a category": "Sélectionner une catégorie",
    "Selected category": "Catégorie sélectionnée",
    "More category": "Plus de catégories",
    "Show latest products": "Afficher les derniers produits",
    "Products per row for mobile": "Produits par ligne pour mobile",
    "Products per row for PC": "Produits par ligne pour PC",
    "Number of products": "Nombre de produits",
    "Image postion": "Position de l'image",
    "Cover": "Couverture",
    "Contain": "Contenir",
    "Image aspect ratio": "Ratio d'aspect de l'image",
    "Header": "En-tête",
    "Paragraph": "Paragraphe",
    "Button": "Bouton",
    "Mobile image": "Image mobile",
    "recommended size": "taille recommandée",
    "Computer image": "Image ordinateur",
    "Image position": "Position de l'image",
    "Image opacity": "Opacité de l'image",
    "Container opacity": "Opacité du conteneur",
    "Button horizontal position": "Position horizontale du bouton",
    "Content horizontal position": "Position horizontale du contenu",
    "Start": "Début",
    "Center": "Centre",
    "End": "Fin",
    "Button vertical position": "Position verticale du bouton",
    "Content vertical position": "Position verticale du contenu",
    "Top": "Haut",
    "Middle": "Milieu",
    "Bottom": "Bas",
    "Images per row mobile": "Images par ligne pour mobile",
    "Images per row PC": "Images par ligne pour PC",
    "Image shape": "Forme de l'image",
    "Add image with link": "Ajouter une image avec un lien",
    "Slide period": "Période de diapositive",
    "Add slide": "Ajouter une diapositive",
    "Image": "Image",
    "Rating": "Évaluation",
    "Content horizontal postion": "Position horizontale du contenu",
    "Content vertical postion": "Position verticale du contenu",
    "Obtenir votre e-store dans hanotify": "Obtenez votre e-store dans Hanotify",
    "Are you sure you want to copy this value \"{cost}\" to all home shipping cost fields ?": "Êtes-vous sûr de vouloir copier cette valeur \"{cost}\" à tous les champs de frais de livraison à domicile ?",
    "Are you sure you want to copy this value \"{cost}\" to all office shipping cost ?": "Êtes-vous sûr de vouloir copier cette valeur \"{cost}\" à tous les champs de frais de livraison au bureau ?",
    "Visitor \"{vistorId}\" is blocked": "Le visiteur \"{vistorId}\" est bloqué",
    'Visitor "{vistorId}" is not blocked anymore':'Le visiteur "{vistorId}" n\'est plus bloqué',
    "Ship with {account}": "Expédier avec {account}",
    "Visitor {id}": "Visiteur {id}",
    "To pay using {method} please send the money to the creditials below": "Pour payer en utilisant {method}, veuillez envoyer l'argent aux coordonnées ci-dessous",
    "{months} Months": "{months} Mois",
    "Are you sure you want to copy this value \"{price}\" to all prices fields ?": "Êtes-vous sûr de vouloir copier cette valeur \"{price}\" à tous les champs de prix ?",
    "Are you sure you want to copy this value \"{price}\" to all original prices fields ?": "Êtes-vous sûr de vouloir copier cette valeur \"{price}\" à tous les champs de prix d'origine ?",
    "Are you sure you want to copy this value \"{sku}\" to all SKU fields ?": "Êtes-vous sûr de vouloir copier cette valeur \"{sku}\" à tous les champs SKU ?",
    "Send to {name}": "Envoyer à {name}",
    "Order {order}: ": "Commande {order} : ",
    "Account {num}": "Compte {num}",
    "Add menu item to \"{name}\"": "Ajouter un élément de menu à \"{name}\"",
    "If the screen of the client device is smaller than {bp} pixels your menu content will appear on the sidebar, and if it is bigger than {bp} pixels the menu content will appear in the header.": "Si l'écran de l'appareil du client est plus petit que {bp} pixels, le contenu de votre menu apparaîtra dans la barre latérale, et s'il est plus grand que {bp} pixels, le contenu du menu apparaîtra dans l'en-tête.",
    "Image {count}": "Image {count}",
    "Customers reviews": "Avis des clients",
    "Product {count}": "Produit {count}",
    "Review {count}": "Avis {count}",
    "Slide {count}": "Diapositive {count}",
    "Abandoned orders" :"Commandes abandonnées",
    "{days} days left": "{days} jours restants",
    "Ecotrack / DHD / Rocket and many others": "Ecotrack / DHD / Rocket et bien d'autres",
    "Are you sure you want to leave?": "Êtes-vous sûr de vouloir partir?",
    "Images over 200KB will be optimized": "Les images de plus de 200 Ko seront optimisées",
    "Silence": "",
    "Loud": "Fort",
    "This category has no products": "Cette catégorie n\'a aucun produit",
    "Android app": "Application",
    "IPhone app": "Application",
    "Soon": "bientôt",
    "Pricing": 'Tarifs',
    "You can only send one order at a time because Maystro still uses 48 states system": "Vous ne pouvez envoyer qu'une seule commande à la fois car Maystro utilise toujours le système à 48 états",
    "Text button": "Bouton texte",
    "Radio button": "Bouton radio",
    "Drop down": "Liste déroulante",
    "Colored square": "Carré coloré",
    "Image with text": "Image avec texte",
    "Put 0 for free shipping and empty space if there was no shipping": "Mettez 0 pour la livraison gratuite et un espace vide s'il n'y a pas eu de livraison",
    "Watch tutorial": "Regarder le tutoriel",
    "Tutorial": 'Regarder',
    "Google sheet is set successfully": "La Google sheets est définie avec succès",
    "Full name and username": "Nom complet et nom d'utilisateur",
    "Your full name must be at least 3 latters long": "Votre nom complet doit comporter au moins 3 lettres",
    "Your email is not valid": "Votre adresse e-mail n'est pas valide",
    "Register now": "Inscrire maintenant",
    'Upload images from iphone directly no need to convert .HEIC / .HEIV images': "Téléchargez des images depuis un iPhone directement, sans besoin de convertir les images .HEIC / .HEIV",
    "Images load very fast because of our optimization algorithm": "Les images se chargent très rapidement grâce à notre algorithme d'optimisation",
    "Page builder for customized stores": "Constructeur de pages pour des boutiques personnalisées",
    "Send all your orders with one click": "Envoyez toutes vos commandes en un clic",
    "The best fake orders blocking system": "Le meilleur système de blocage des fausses commandes",
    "Get notifications both in mobile app and browser": "Recevez des notifications à la fois sur l'application mobile et le navigateur",
    "Create multiple stores with one account": "Créez plusieurs boutiques avec un seul compte",
    "Supported languages are arabic, french and english": "Les langues prises en charge sont l'arabe, le français et l'anglais",
    "Learn more": "En savoir plus",
    'Select currencies': 'Sélectionner les devises',
    'Payment bank': 'Banque de paiement',
    'Payment amount': 'Montant du paiement',
    'Admin note': 'Note de l\'administrateur',
    'Payment image': 'Image du paiement',
    'No payments yet': 'Aucun paiement pour le moment',
    'You do not have an affiliation link yet': 'Vous n\'avez pas encore de lien d\'affiliation',
    'Create your affiliation link': 'Créer votre lien d\'affiliation',
    'Affiliate program': 'Programme d\'affiliation',
    'Total money earned': 'Total de l\'argent gagné',
    'Total money withdrawed': 'Total de l\'argent retiré',
    'Unwithdrawed money': 'Argent non retiré',
    'Referral link': 'Lien de parrainage',
    'Withdraw methods': 'Méthodes de retrait',
    'Please select the payment methods you want to get your money with': 'Veuillez sélectionner les méthodes de paiement que vous souhaitez utiliser pour recevoir votre argent',
    'When your unwithdrawed money exceeds 4000 DZ you can ask for a checkout': 'Lorsque votre argent non retiré dépasse 4000 DZ, vous pouvez demander un retrait',
    'Withdraw your money': 'Retirez votre argent',
    'You will recieve your payement is the next 24 to 48 hours, you will get an email when we do': 'Vous recevrez votre paiement dans les prochaines 24 à 48 heures, vous recevrez un e-mail lorsque nous le ferons',
    'Show conditions': 'Afficher les conditions',
    'Show payments history': 'Afficher l\'historique des paiements',
    'How does it work ?': 'Comment ça fonctionne ?',
    'When a new user registers with your affiliation link you get a commission each time he makes a payment, and he will get a discount on his first purchase': 'Lorsqu\'un nouvel utilisateur s\'inscrit avec votre lien d\'affiliation, vous recevez une commission chaque fois qu\'il effectue un paiement, et il bénéficiera d\'une réduction sur son premier achat',
    'The affiliate commission': 'La commission d\'affiliation',
    '30% of the new user first payment no matter how much he paid': '30 % du premier paiement du nouvel utilisateur, quel que soit le montant payé',
    '20% of the new user second payment no matter how much he paid': "20 % du deuxième paiement du nouvel utilisateur, quel que soit le montant qu'il a payé",
    '10% of the new user third payment no matter how much he paid': '10 % du troisième paiement du nouvel utilisateur, quel que soit le montant qu\'il a payé ',
    '5% of all the new user payments starting from the forth payemnt no matter how much he paid': '5 % de tous les paiements du nouvel utilisateur à partir du quatrième paiement, quel que soit le montant payé',
    'The new user discount': 'La réduction pour le nouvel utilisateur',
    'New user get an extra 10% discount on his first plan no matter how much that plan costs': 'Le nouvel utilisateur bénéficie d\'une réduction supplémentaire de 10 % sur son premier plan, quel que soit le coût de ce plan',
    'Username or email': 'Nom de utilistauer or email',
    "Watch our introduction video": "Voir votre vidéo d'introduction",
    "Download our SMS app (Android only)": "Téléchargez notre application SMS (Android uniquement)",
    "Affiliate": "affiliation",
    'Since you have been referraled by "{name}" you get an extra 10% discount on your first plan': 'Puisque vous avez été référé par "{name}", vous bénéficiez \'une réduction supplémentaire de 10 % sur votre premier forfait',
    'Send SMS to clients': 'Envoyer des SMS aux clients',
    'Send an sms to your clients when they make an order so they feel committed to your product and not cancel': 'Envoyez un SMS à vos clients lorsqu’ils passent une commande pour qu’ils se sentent engagés envers votre produit et ne l’annulent pas',
    'Send automatic sms to clients after ordering': 'Envoyer des SMS automatiques aux clients après la commande',
    "Watchs store": "Magasin de montres",
    "Clothes shop": "Magasin de vêtements",
    "For each store you can select the message you want to send the client when makes an order.": "Pour chaque magasin, vous pouvez sélectionner le message que vous souhaitez envoyer au client lorsqu'il passe une commande.",
    "When a client makes an order our app will recieve that order and sends an sms with you default sim card.": "Lorsqu'un client passe une commande, notre application recevra cette commande et enverra un SMS avec votre carte SIM par défaut.",
    "After installing the app, login using your hanotify creditials. give the app the permission and you will see your stores.": "Après avoir installé l'application, connectez-vous en utilisant vos identifiants Hanotify. Donnez les autorisations nécessaires à l'application et vous verrez vos magasins.",
    "In case your phone was offline for a while and you recieved orders when your phone was offline, when you connect to the intenet your phone will still send sms messages to those clients, you can deactivate this in the app": "Si votre téléphone a été hors ligne pendant un certain temps et que vous avez reçu des commandes pendant cette période, lorsque vous vous reconnectez à Internet, votre téléphone enverra toujours des SMS à ces clients. Vous pouvez désactiver cette option dans l'application.",
    "Always keep your phone on and connected to the internet": "Gardez toujours votre téléphone allumé et connecté à Internet.",
    "When the app is installed, give it a permission to send sms and a permission to always work on the background": "Lorsque l'application est installée, donnez-lui l'autorisation d'envoyer des SMS et de toujours fonctionner en arrière-plan.",
    "Setup": "Installation",
    "Store Exemples": "Exemples de magasin",
    "Open link in an external page": "Ouvrir le lien dans une page externe",
    'Customize form': 'Personnaliser le formulaire',
    'Form title': 'Titre du formulaire',
    '↓Fill the form below to order↓': '↓Remplissez le formulaire ci-dessous pour commander↓',
    'Add note': 'Ajouter une note',
    'Full name field': 'Champ du nom complet',
    'Phone number field': 'Champ du numéro de téléphone',
    'Email field': 'Champ d\'e-mail',
    'Address field': 'Champ d\'adresse',
    'City field': 'Champ de ville',
    'State field': 'Champ d\'état',
    'Client note field': '',
    'Buy button': 'Bouton d\'achat',
    'Buy now': 'Acheter maintenant',
    'Required': 'Requis',
    'Add credit to your account here': 'Ajoutez du crédit à votre compte ici',
    'Form settings': 'Paramètres du formulaire',
    "When you change your password. all devices using your account will be logged out including this device": "Lorsque vous changez votre mot de passe. tous les appareils utilisant votre compte seront déconnectés, y compris cet appareil",
    "You have been logged out": "Vous avez été déconnecté.",
    'Now send a screenshot of the transaction': "Envoyez maintenant une capture d'écran de la transaction",
  },
  'en': {}
}

export function translate(key, values={}, language=localStorage.getItem('language')) {
  // Get the translated string from the translations object
  let translation = dict[language][key] || key;
  // If the translation doesn't exist, return the key itself
  if (!translation) {
    return key;
  }

  // Replace the placeholders with actual values
  Object.keys(values).forEach((placeholder) => {
    const regex = new RegExp(`{${placeholder}}`, 'g');
    translation = translation.replace(regex, values[placeholder]);
  });

  return translation;
}

export const axiosTr = axios.create({
  headers: {
      'Accept-Language': localStorage.getItem('language'), // Set language header
  },
});


export function hexToRgb(hex) {
    hex = hex.replace(/^#/, '');  
    let r = parseInt(hex.slice(0, 2), 16);
    let g = parseInt(hex.slice(2, 4), 16);
    let b = parseInt(hex.slice(4, 6), 16);
  
    // Return the formatted RGB string
    return `${r}, ${g}, ${b}`;
}

export function isArabicOrLatinLetter(char) {
  const combinedRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFFA-Za-z \u00C0-\u00FF\u0100-\u017F\u0180-\u024F]/;
  return combinedRegex.test(char);
}

export function isAccaptableCharacter(char) {
  // Regular expression for allowed characters in a username
  const regex = /^[A-Za-z0-9@\-_.]$/;
  // Test the character against the regular expression
  return regex.test(char);
}
export function isAccaptableCharacterDomain(char) {
  // Regular expression for allowed characters in a username
  const regex = /^[A-Za-z0-9\-.]$/;
  // Test the character against the regular expression
  return regex.test(char);
}
const getScrollableAncestor = (element) => {
  let currentNode = element.parentElement;

  while (currentNode && currentNode !== document.body) {
    const style = window.getComputedStyle(currentNode);
    const overflow = style.overflow;
    const overflowY = style.overflowY;

    // Check if the element is scrollable
    if ( overflow  === 'auto' || overflowY  === 'auto' || overflow  === 'scroll' || overflowY  === 'scroll' ) {
      return currentNode;
    }

    // Move to the parent node
    currentNode = currentNode.parentElement;
  }

  // Default to the body or documentElement if no scrollable ancestor is found
  return document.scrollingElement || document.documentElement;
};
export function adjustScrollPosition(element, delta= 0) {
  // Get the element's bounding box relative to the viewport
  const rect = element.getBoundingClientRect();
  const scrollingContainer = getScrollableAncestor(element)
  // Calculate the amount of space needed to ensure the dropdown is fully visible
  const spaceBelow = scrollingContainer.clientHeight - rect.bottom + delta;

  if (spaceBelow < 0) { // If there's not enough space below
    // Scroll the window to make the drop-down fully visible
    scrollingContainer.scrollBy({
      top: -spaceBelow,
      behavior: 'smooth'
    });
  }
}

export function adjustScrollToTop(element, delta= 0) {
  // Get the element's bounding box relative to the viewport
  const rect = element.getBoundingClientRect();
  const scrollingContainer = getScrollableAncestor(element)
  // Calculate the amount of space needed to ensure the dropdown is fully visible

  scrollingContainer.scrollBy({
    top: rect.top + delta,
    behavior: 'smooth'
  });
}


function getImageDimensions(file) {
  const imageUrl = URL.createObjectURL(file);
  const img = new Image();
  return new Promise((resolve, reject) => {
    // Set up event listeners
    img.onload = () => {
      // Get image dimensions
      const dimensions = {
        width: img.naturalWidth,
        height: img.naturalHeight,
      };
      // Revoke the object URL to free up memory
      URL.revokeObjectURL(imageUrl);
      resolve(dimensions);
    };

    img.onerror = (err) => {
      URL.revokeObjectURL(imageUrl);
      reject(new Error("Failed to load image"));
    };

    // Load the image
    img.src = imageUrl;
  })
}

export async function fileToBase64(file) {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
          const base64String = reader.result.split(',')[1]; // Remove data URL prefix
          const dataUrl = `data:${file.type};base64,${base64String}`;
          resolve(dataUrl);
      };

      reader.onerror = (error) => {
          reject(error);
      };
      reader.readAsDataURL(file);
  });
}

const isIphone = /iPhone/i.test(navigator.userAgent)
export async function optimizeImages(files, quality, maxSide, format, max=undefined) {
  let filesArray = max ? Array.from(files).slice(0, max) : Array.from(files);
  const promises = filesArray.map((file) => {
    return new Promise(async (resolve, reject) => {    
      // Handle HEIC format
      if (file.size < 200 * 1024) {
        resolve(file)
      }

      let fileToProcess
      if (file.type === 'image/heic' || file.type === 'image/heif' || file.type === "") {
        try {
          fileToProcess = await heic2any({ blob: file, toType: `image/jpeg`, quality });
        } catch (error) {
          console.error('Error converting HEIC to desired format:', error);
          resolve(null)
        }
      }else{
        fileToProcess = file
      }
      // Compress the image
      try {
          let extension;
          let imageFormat ;
          if (format) {
            if (format === "webp") {
              extension = "webp";
            } 
            else if (format === "jpeg" || format === "jpg") {
              extension = "jpeg";
            }
            else if (format === "png") {
              extension = "png";
            } else {
              extension = fileToProcess.type.replace('image/', '');
            }
          } else {
            imageFormat = fileToProcess.type.replace('image/', '')
            if (isIphone){
              extension = imageFormat === "jpeg" || imageFormat === "jpg" || imageFormat === "png" ? 'jpeg' : imageFormat 
            }
            else{
              extension = imageFormat === "jpeg" || imageFormat === "jpg" || imageFormat === 'webp' ? 'webp' : (imageFormat === "png" ? "jpeg" : imageFormat) 
            }
          }
          let {width, height} = await getImageDimensions(fileToProcess);
          if (width > maxSide){
            height = height * maxSide / width
            width = maxSide
          }
          let mul
          if (width > height){
            mul =  width / height
          }else{
            mul = height / width
          }
          const options = {
            maxSizeMB: 0.3 * mul, // Maximum size in MB
            maxWidthOrHeight: Math.max(width, height),
            useWebWorker: false,
            preserveExif: true,
            initialQuality: isIphone ? 1 : quality,
            fileType: 'image/' + extension
          };
          // let compressed = await optimizeAndResizeImage(fileToProcess, width, height, extension)
          let compressed = await imageCompression(fileToProcess, options)
          resolve(compressed) 

      } catch (error) {
        console.error('Error compressing image:', error);
        resolve(null)
      }
    });
  });
  return Promise.all(promises.filter(elem=>!!elem));
}

export function trimStart(str, char=' ') {
  let startIndex = 0;
  while (startIndex < str.length && str[startIndex] === char) {
    startIndex++;
  }
  return str.slice(startIndex);
}

export function slugify(text) {
  return text
    .toLowerCase()               // Convert to lowercase
    .replace(/\s+/g, '-')        // Replace spaces with hyphens
    .replace(/[^\w\-]+/g, '')    // Remove all non-alphanumeric characters except hyphens
    .replace(/\-\-+/g, '-')      // Replace multiple hyphens with a single hyphen
    .replace(/^-+/, '')          // Remove leading hyphens
}

export function isValidHexColor(hex) {
  // Regular expression to check if the string is a valid hex color code
  const hexPattern = /^#([0-9A-Fa-f]{3}){1,2}$/;
  return hexPattern.test(hex);
}

export const deleteImage = async(url, type, extraData)=>{
  try{
    await axiosTr.post(
        filesUrl + '/delete-image',
        {
            image: url,
            type,
            store_id: localStorage.getItem('storeId'),
            ...extraData
        },
        {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        }
    )
    return true
  }catch{
    return false
  }
    
}

export const deleteImages = async(url_list, type)=>{
  try{
    if (url_list.length === 0) return true
    await axiosTr.post(
        filesUrl + '/delete-images',
        {
            images_list: url_list,
            type,
            store_id: localStorage.getItem('storeId')
        },
        {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        }
    )
    return true
  }catch{
    return false
  }
    
}

export function getCombinations(variants) {
  const variantOptions = variants.map(variant => Object.values(variant.options));
  if (variantOptions.length === 0) {
    return [];
  }
  function combine(optionsArray) {
    if (optionsArray.length === 0) {
      return [[]];
    }

    const result = [];
    const firstOptionSet = optionsArray[0];
    const remainingOptionSets = optionsArray.slice(1);

    const remainingCombinations = combine(remainingOptionSets);

    for (const option of firstOptionSet) {
      for (const combination of remainingCombinations) {
        result.push([option, ...combination]);
      }
    }

    return result;
  }

  const combinations = combine(variantOptions);

  return combinations.map(combination => {
    const combinedObject = {};

    combination.forEach((option, index) => {
      const variantName = variants[index].name;
      combinedObject[variantName] = option;
    });

    return combinedObject;
  });
}

export function TimeElapsed(time) {
    const language = localStorage.getItem('language')
    const languagePhrases = {
        ar: 'الآن',
        fr: 'juste maintenant',
        en: 'just now'
    };
    const calculateElapsedTime = () => {
        const units = {
            ar: ['ثانية', 'دقيقة', 'ساعة', 'يوم', 'أسبوع', 'سنة'],
            fr: ['secs', 'mins', 'heures', 'jours', 'semaines', 'an'],
            en: ['secs', 'mins', 'hours', 'days', 'weeks', 'years']
        };

        const unitsInSeconds = [1, 60, 3600, 86400, 604800, 31536000];

        const timeDiff = Math.floor((new Date() - new Date(time)) / 1000);

        if (timeDiff < 60) {
            return languagePhrases[language] || languagePhrases.en;
        }

        for (let i = unitsInSeconds.length - 1; i >= 0; i--) {
            const unitInSeconds = unitsInSeconds[i];
            if (timeDiff >= unitInSeconds) {
                const value = Math.floor(timeDiff / unitInSeconds);
                const unit = units[language] ? units[language][i] : units.en[i];
                const languagePhrases = {
                    ar: 'منذ',
                    fr: 'depuis',
                    en: 'ago'
                };
                const phrase = languagePhrases[language] || languagePhrases.en;
                return language !== 'en' ? `${phrase} ${value} ${unit}` : `${value} ${unit} ${phrase}`;
            }
        }

        return languagePhrases[language];
    };


    return calculateElapsedTime();
}

export function formatDate(date) {
  // Define the month names for each language
  date = new Date(date)
  // Extract time and date components
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');

  // Return formatted string
  return `${hours}:${minutes}&nbsp;&nbsp;&nbsp;${day}/${month}`;
}

export function fullDate(date) {
  // Define the month names for each language
  date = new Date(date)
  // Extract time and date components
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  // Return formatted string
  return `${day}/${month}/${year}`;
}


export function capitalizeFirstLetter(word) {
  if (typeof word !== 'string' || word.length === 0) {
      return word;
  }
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export function objectToCsv(dataObject) {
  const headers = Object.keys(dataObject[0]).join(","); // Extract headers from the object keys
  const rows = dataObject.map(obj => Object.values(obj).join(",")).join("\n"); // Convert each object to a CSV row
  return `${headers}\n${rows}`;
}
export function downloadCsv(csvData, filename) {
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.click();
  URL.revokeObjectURL(url); // Clean up the URL object after the download
}

export function copyToClipboard(text) {
  navigator.clipboard.writeText(text).then(() => {
      console.log("Text copied to clipboard.");
  }).catch(err => {
      console.error("Failed to copy text: ", err);
  });
}

export const componentLoader = async(importFunction)=>{
  const loading = document.getElementById('loading__div')
  const header = document.getElementById('Header')
  if (loading) loading.style.display='block'
  if (header) header.style.marginTop = '4px'
  await importFunction()
  if (loading) loading.style.display='none'
  if (header) header.style.removeProperty('margin-top')
  return null
}

export function extractImageUrls(htmlString) {
  // Create a temporary DOM element to parse the HTML string
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  // Select all image elements within the HTML
  const imgTags = tempDiv.getElementsByTagName('img');

  // Extract the src attribute from each image tag
  const imgUrls = Array.from(imgTags).map(img => img.src);

  return imgUrls;
}
export function addLazyLoadingToImages(htmlString) {
  // Step 1: Create a template element to hold the HTML string
  const template = document.createElement('template');
  
  // Step 2: Assign the HTML string to the template's innerHTML
  template.innerHTML = htmlString;

  // Step 3: Select all <img> tags inside the template
  const imgTags = template.content.querySelectorAll('img');

  // Step 4: Loop through all <img> tags and add loading="lazy" if not already present
  imgTags.forEach(img => {
    if (!img.hasAttribute('loading')) {
      img.setAttribute('loading', 'lazy');
    }
  });

  // Step 5: Return the updated HTML as a string
  return template.innerHTML;
}

export function cleanHtml(htmlString) {
  // Create a temporary DOM element to parse the HTML string
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  // Function to recursively check if a node is empty
  function checkNode(node) {
      // Skip <img> tag check - it should be considered as content
      if (node.tagName === 'IMG' || node.tagName === 'IFRAME') return false;

      // Check if the node contains non-empty text content
      if (node.nodeType === Node.TEXT_NODE && node.nodeValue.trim() !== '') {
          return false;
      }

      // Recursively check child nodes
      for (let child of node.childNodes) {
          if (!checkNode(child)) {
              return false;
          }
      }

      return true;
  }

  // Start checking from the body of the parsed HTML
  return checkNode(tempDiv) ? '' : htmlString;
}

export function createArray(n) {
  return Array.from({ length: n }, (v, i) => i);
}

export const scrollToTop=(elem)=>{
  elem.scrollIntoView({
    behavior: 'smooth',  // Optional: Add smooth scrolling
    block: 'start',      // Align the element to the top of the scrollable container
    inline: 'nearest'    // For horizontal scroll (if needed)
  });
}

export function numberToHex(number) {
  if (number < 0 || number > 99) {
      throw new Error("Number must be between 0 and 99");
  }
  // Convert the number to a value between 0 and 255
  const hexValue = Math.round(number * 255 / 99);
  // Convert to hexadecimal and pad with '0' if necessary
  return hexValue.toString(16).padStart(2, '0').toUpperCase();
}

export function hexToNumber(hex) {
  if (!/^[0-9A-Fa-f]{2}$/.test(hex)) {
      throw new Error("Input must be a valid two-digit hexadecimal string (00 to FF)");
  }
  // Convert the hex to an integer
  const decimalValue = parseInt(hex, 16);
  // Scale the value back to 0-99 range
  return Math.round(decimalValue * 99 / 255);
}

export function mergeObjects(obj1, obj2) {
  // Helper function to recursively merge objects
  function recursiveMerge(obj1, obj2) {
      // Create a new object to store the result
      const result = {};

      // Loop through each key in obj1
      for (let key in obj1) {
          if (obj1.hasOwnProperty(key)) {
              // If obj1[key] is an object and obj2[key] is also an object, recursively merge
              if (typeof obj1[key] === 'object' && obj1[key] !== null && obj2.hasOwnProperty(key) && typeof obj2[key] === 'object' && obj2[key] !== null) {
                  result[key] = recursiveMerge(obj1[key], obj2[key]);
              } else {
                  // If obj2 has the same key, use its value; otherwise, use obj1's value
                  result[key] = obj2.hasOwnProperty(key) ? obj2[key] : obj1[key];
              }
          }
      }

      // Return the merged result
      return result;
  }

  // Call the recursive merge function
  return recursiveMerge(obj1, obj2);
}


export function darkenHexColor(hex, percent) {
    // Ensure hex starts with '#'
    if(hex){
      if (hex[0] === '#') {
        hex = hex.slice(1);
      }
    
      // Convert hex to RGB
      let r = parseInt(hex.slice(0, 2), 16);
      let g = parseInt(hex.slice(2, 4), 16);
      let b = parseInt(hex.slice(4, 6), 16);
    
      // Calculate darkening factor
      const factor = (100 - percent) / 100;
    
      // Darken each color component
      r = Math.round(r * factor);
      g = Math.round(g * factor);
      b = Math.round(b * factor);
    
      // Ensure values are within the valid range
      r = Math.max(0, Math.min(255, r));
      g = Math.max(0, Math.min(255, g));
      b = Math.max(0, Math.min(255, b));
    
      // Convert RGB back to hex
      const newHex = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
    
      return newHex;
    }
}


export function getPageList(currentPage, lastPage) {
  const pages = [];

  // Always show the first two pages
  pages.push(1);
  if (currentPage > 5) {
    pages.push('...');
  }

  // Determine the range of pages around the current page
  for (let i = Math.max(2, currentPage - 3); i <= Math.min(lastPage - 1, currentPage + 3); i++) {
      pages.push(i);
  }

  // Add dots after the current page if needed
  if (currentPage < lastPage - 4) {
      pages.push('...');
  }

  // Always show the last two pages
  if (lastPage > 1) {
      pages.push(lastPage);
  }

  return pages;
}

export function getDefaultLanguage() {
  // Get the user's language setting from the browser
  const userLang = navigator.language || navigator.userLanguage;

  // Check the first two characters of the language code
  if (userLang.startsWith('fr')) {
      return 'fr';
  } else if (userLang.startsWith('en')) {
      return 'en';
  } else if (userLang.startsWith('ar')) {
      return 'ar';
  } else {
      // Fallback to 'fr' if none of the above matches
      return 'fr';
  }
}


export function isValidEmail(email) {
  // Regular expression for a basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Test the provided email against the regular expression
  return emailRegex.test(email);
}

export function daysUntil(targetDate) {
  // Parse the input date and get the current date
  const target = new Date(targetDate);
  const now = new Date();

  // Calculate the difference in time (milliseconds)
  const difference = target - now;

  // Convert the time difference to days
  const daysLeft = Math.ceil(difference / (1000 * 60 * 60 * 24));

  return daysLeft
}


export const isValidPhoneNumber =(phoneNumber)=>{
  const number = phoneNumber.trim().replace(/-/g, "");
  const mobilePattern = /^0[567]\d{8}$/;
  const landlinePattern = /^0(2[1-9]|3[0-4]|4[0-9])\d{6}$/;
  return mobilePattern.test(number) || landlinePattern.test(number);
}

export function getCookie(name) {
  let cookies = document.cookie.split('; ');
  for (let cookie of cookies) {
      let [key, value] = cookie.split('=');
      if (key === name) {
          return decodeURIComponent(value);
      }
  }
  return null; // Return null if cookie is not found
}


export const getIpAddress = async()=>{
      const response = await fetch('https://api64.ipify.org?format=json')
      const data = await response.json()
      return data.ip
}

var userAgent = navigator.userAgent || navigator.vendor || window.opera;
export const isFacebookApp = userAgent.includes("FBAN") || userAgent.includes("FBAV") || userAgent.includes("Instagram");
export const openUrlOutsideFacebookApp=(url)=>{
    // Detect user agent
    const isAndroid = /android/i.test(userAgent);
    var isIOS = /iPhone|iPad|iPod/i.test(userAgent);
    if (isAndroid) {
        // Try opening in Chrome using intent
        window.open(`intent://${url.replace('https://', "")}/#Intent;scheme=https;package=com.android.chrome;end;`, "_blank"); 
        
    }
    else if (isIOS){
      var safariWindow = window.open(url, "_blank"); 
      if (!safariWindow) {
          window.location.href = url; // Fallback if pop-ups are blocked
      }
    }
    else {
      window.open(url, "_blank"); 
    }
}