import React, { lazy, useEffect, useMemo } from 'react'
import { createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import Container from './components/main-components/Container'
import BrowserContextProvider from './store/browser-context';
import UserContextProvider, { useUserContext } from './store/user-context';
import Redirect from 'pages/Redirect';
import SuspenseComponent from 'components/SuspenseComponent';
import Support from 'pages/Support';
import { axiosTr } from 'utils/utils';
import { apiUrl } from 'constants/urls';
import GlobalDialogContextProvider from 'store/global-context-provider';

const ImportHome = ()=>import('./pages/Home')
const Home  = lazy(ImportHome)

const ImportLogin = ()=>import('./pages/Login')
const Login  = lazy(ImportLogin)

const ImportRegister = ()=>import('./pages/Register')
const Register  = lazy(ImportRegister)

const ImportDashboard = ()=>import('./pages/Dashboard') 
const Dashboard  = lazy(ImportDashboard)

const ImportOrders= ()=>import('./pages/Orders')
const Orders  = lazy(ImportOrders)

const ImportAddProduct= ()=>import('./pages/AddProduct')
const AddProduct  = lazy(ImportAddProduct)

const ImportShipping= ()=>import('./pages/settings/Shipping')
const Shipping  = lazy(ImportShipping)

const ImportProducts= ()=>import('./pages/Products')
const Products  = lazy(ImportProducts)


const ImportSettings= ()=>import('./pages/Settings')
const Settings  = lazy(ImportSettings)

const ImportStoreCustomizePage= ()=>import('./pages/StoreCustomizePage')
const StoreCustomizePage  = lazy(ImportStoreCustomizePage)

const ImportApps= ()=>import('./pages/Apps')
const Apps  = lazy(ImportApps)


const ImportGoogleSheets= ()=>import('./pages/apps/GoogleSheets')
const GoogleSheets  = lazy(ImportGoogleSheets)

const ImportFacebookPixel= ()=>import('pages/apps/FacebookPixel')
const FacebookPixel  = lazy(ImportFacebookPixel)

const ImportTiktokPixels= ()=>import('pages/apps/TiktokPixels')
const TiktokPixels  = lazy(ImportTiktokPixels)

const ImportMessagingApps= ()=>import('pages/apps/MessagingApps')
const MessagingApps  = lazy(ImportMessagingApps)

const SendSMSToClients  = lazy(()=>import('pages/apps/SendSMSToClients'))

const ImportDesign = ()=>import('pages/store/pages/Design')
const Design  = lazy(ImportDesign)

const ImportCategories = ()=>import('pages/store/pages/Categories')
const Categories  = lazy(ImportCategories)

const CustomizForm  = lazy(()=>import('./pages/CustomizForm'))
const ConversionApi  = lazy(()=>import('pages/apps/ConversionApi'))
const EventsApi  = lazy(()=>import('pages/apps/EventsApi'))
const ShippingCompanies  = lazy(()=>import('pages/settings/ShippingCompanies'))
const Procolis  = lazy(()=>import('pages/settings/shipping-companies/Procolis'))
const ZRExpress  = lazy(()=>import('pages/settings/shipping-companies/ZRExpress'))
const Yalidine  = lazy(()=>import('pages/settings/shipping-companies/Yalidine'))
const Guepex  = lazy(()=>import('pages/settings/shipping-companies/Guepex'))
const Ecotrack  = lazy(()=>import('pages/settings/shipping-companies/Ecotrack'))
const Maystro  = lazy(()=>import('pages/settings/shipping-companies/Maystro'))
const Noest  = lazy(()=>import('pages/settings/shipping-companies/Noest'))
const AccountSettings  = lazy(()=>import('pages/AccountSettings'))
const UserNames  = lazy(()=>import('pages/account-settings/pages/UserNames'))
const CustomDomain  = lazy(()=>import('pages/store/pages/CustomDomain'))
const Email = lazy(()=>import('pages/account-settings/pages/Email'))
const Password = lazy(()=>import('pages/account-settings/pages/Password'))
const YourStores = lazy(()=>import('pages/YourStores'))
const Payment = lazy(()=>import('pages/Payment'))
const Plans = lazy(()=>import('pages/Plans'))
const ResetPassword = lazy(()=>import('pages/ResetPassword'))
const StoreSettings = lazy(()=>import('pages/store/pages/StoreSettings'))
const ComponentsDesign = lazy(()=>import('pages/store/pages/ComponentsDesign'))
const StorePages = lazy(()=>import('pages/store/pages/StorePages'))
const Socials = lazy(()=>import('pages/store/pages/Socials'))
const PhoneNumber = lazy(()=>import('pages/account-settings/pages/PhoneNumber'))

const FacebookAdHome = lazy(()=>import('pages/FacebookAdHome'))
const Affiliate = lazy(()=>import('pages/Affiliate'))

//LAZY LOAD PAGES

const AppWithoutProviding = () => {
  const {userData} = useUserContext()
  const router = useMemo(()=>createBrowserRouter([
    {
      index: true,
      element: <SuspenseComponent Component={Home} />,
    },
    {
      path: '/facebook-ad-home',
      element: <SuspenseComponent Component={FacebookAdHome} /> 
    },
    {
      path: '/login',
      element: userData ? <Redirect redirect='/dashboard' /> : <SuspenseComponent Component={Login} />,
    },
    {
      path: '/register',
      element: userData ? <Redirect redirect='/dashboard' /> :  <SuspenseComponent Component={Register} />
    },
    {
      path: '/register/:aid',
      element: userData ? <Redirect redirect='/dashboard' /> :  <SuspenseComponent Component={Register} />
    },
    {
      path: '/reset-password',
      element: userData ? <Redirect redirect='/dashboard' /> : <SuspenseComponent Component={ResetPassword}/>,
    },
    {
      path: userData ? undefined :  "/plans",
      element: <SuspenseComponent Component={Plans}/>,
    },
    {
      path: "/",
      element: userData ? <Container /> : <Redirect redirect='/login' />,
      children: [,
        {
          path: "dashboard",
          element: <SuspenseComponent Component={Dashboard}/>,
        },
        {
          path: "user/account-settings",
          element: <SuspenseComponent Component={AccountSettings}/>,
        },
        {
          path: "user/account-settings/names",
          element: <SuspenseComponent Component={UserNames}/>,
        },
        {
          path: "user/account-settings/email",
          element: <SuspenseComponent Component={Email}/>,
        },
        {
          path: "user/account-settings/password",
          element: <SuspenseComponent Component={Password}/>,
        },
        {
          path: "user/account-settings/phone-number",
          element: <SuspenseComponent Component={PhoneNumber}/>,
        },
        {
          path: "user/your-stores",
          element: <SuspenseComponent Component={YourStores}/>,
        },
        {
          path: "redirect",
          element: <Redirect/>,
        },
        {
          path: "orders",
          element: <Outlet/>,
          children:[
            {
              index: true,
              element: <SuspenseComponent Component={Orders} key={[false, false]} abandoned={false} />,
            },
            {
              path: 'abandoned-orders',
              element: <SuspenseComponent Component={Orders} key={[false, true]} abandoned={true} />,
            },
            {
              path: 'archived-orders',
              element: <SuspenseComponent Component={Orders} key={[true, false]} archived={true} />,
            }
          ]
        },
        {
          path: "products",
          element: <Outlet/>,
          children:[
            {
              index: true,
              element: <SuspenseComponent Component={Products}/>,
            },
            {
              path: ':id',
              element: <SuspenseComponent Component={AddProduct} />,
            }
          ]
        },
        {
          path: "store",
          element: <Redirect redirect={'design'} />
        },
        {
          path: "store",
          element: <Outlet />,
          children: [
            {
              path: 'settings',
              element: <SuspenseComponent Component={StoreSettings} />
            },
            {
              path: 'design',
              element: <SuspenseComponent Component={Design} />
            },
            {
              path: 'components-design',
              element: <SuspenseComponent Component={ComponentsDesign} />,
            },
            {
              path: 'pages',
              element: <SuspenseComponent Component={StorePages} />,
            },
            {
              path: 'custom-domain',
              element: <SuspenseComponent Component={CustomDomain} />
            },
            {
              path: 'socials',
              element: <SuspenseComponent Component={Socials} />,
            },
            {
              path: 'categories',
              element: <SuspenseComponent Component={Categories} />
            },
            {
              path: 'customize-form',
              element: <SuspenseComponent Component={CustomizForm} />,
            },
          ]
        },
        {
          path: "store",
          element: <SuspenseComponent Component={StoreCustomizePage} />,
        },
        {
          path: "stats",
          element: <div />,
        },
        {
          path: "payment",
          element: <SuspenseComponent Component={Payment} />,
        },
        {
          path: "plans",
          element: <SuspenseComponent Component={Plans} />,
        },
        {
          path: "settings",
          element: <SuspenseComponent Component={Settings} />,
        },
        {
          path: "settings/shipping",
          element: <SuspenseComponent Component={Shipping} />,
        },
        {
          path: "settings/shipping-companies",
          element: <SuspenseComponent Component={ShippingCompanies} />,
        },
        {
          path: "settings/shipping-companies/procolis",
          element: <SuspenseComponent Component={Procolis} />,
        },
        {
          path: "settings/shipping-companies/zr-express",
          element: <SuspenseComponent Component={ZRExpress} />,
        },
        {
          path: "settings/shipping-companies/yalidine",
          element: <SuspenseComponent Component={Yalidine} />,
        },
        {
          path: "settings/shipping-companies/guepex",
          element: <SuspenseComponent Component={Guepex} />,
        },
        {
          path: "settings/shipping-companies/ecotrack",
          element: <SuspenseComponent Component={Ecotrack} />,
        },
        {
          path: "settings/shipping-companies/maystro",
          element: <SuspenseComponent Component={Maystro} />,
        },
        {
          path: "settings/shipping-companies/noest",
          element: <SuspenseComponent Component={Noest} />,
        },
        {
          path: "apps",
          element: <SuspenseComponent Component={Apps} />,
        },
        {
          path: "apps/google-sheets",
          element: <SuspenseComponent Component={GoogleSheets} />,
        },
        {
          path: "apps/facebook-pixel",
          element: <SuspenseComponent Component={FacebookPixel} />,
        },
        {
          path: "apps/conversion-api",
          element: <SuspenseComponent Component={ConversionApi} />
        },
        {
          path: "apps/events-api",
          element: <SuspenseComponent Component={EventsApi} />
        },
        {
          path: "apps/tiktok-pixels",
          element: <SuspenseComponent Component={TiktokPixels} />  ,
        },
        {
          path: "apps/messaging-apps",
          element: <SuspenseComponent Component={MessagingApps} />  ,
        },
        {
          path: "apps/send-sms-to-clients",
          element: <SuspenseComponent Component={SendSMSToClients} />  ,
        },
        {
          path: "support",
          element: <SuspenseComponent Component={Support} />,
        },
        {
          path: "affiliate",
          element: <SuspenseComponent Component={Affiliate} /> 
        },
      ],
    },
    {
      path: '*',
      element: <Redirect redirect='/' />
    },
  ]), [userData?.id])
  useEffect(()=>{
    axiosTr.post(
      apiUrl + '/others/register-visitor',
      {
        tracker: localStorage.getItem('tracker'),
      },
    ).then(response=>{
      localStorage.setItem('tracker', response.data.tracker)
    })
  }, [])
  return (
    <RouterProvider  router={router} />
  )
}


const App = ()=>{
  return(
    <GlobalDialogContextProvider>
      <UserContextProvider>
        <BrowserContextProvider>
            <AppWithoutProviding />
        </BrowserContextProvider>
      </UserContextProvider>
    </GlobalDialogContextProvider>
      
  )}

export default App