import { createContext, useContext, useState } from "react";

const  GlobalDialogContext = createContext({
    globalDialog: {
        children: <></>, 
        keepOpen: false,
    },
    setGlobalDialog: ({children, keepOpen})=>{},
})

const GlobalDialogContextProvider = ({children})=>{
    const [globalDialog, setGlobalDialog] = useState(null)

    const defaultValue={
        globalDialog, 
        setGlobalDialog,
    }
    return (
        <GlobalDialogContext.Provider value={defaultValue} >
            {children}
        </GlobalDialogContext.Provider>
    )
}

export default GlobalDialogContextProvider

const useGlobalDialogContext =()=> useContext(GlobalDialogContext)
export {useGlobalDialogContext}