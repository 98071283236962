import React, { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useBrowserContext } from 'store/browser-context'

const SpecialLink = ({to, onClick=()=>{}, ...props}) => {
  const location = useLocation()

  const {setOpenSideBar, screenWidth} = useBrowserContext()

  const checkLink =useMemo(()=>{
    return (window.location.pathname.startsWith('/products/') || window.location.pathname.startsWith('/store/pages'))
  }, [location])

  const clickHandler=()=>{
    if(screenWidth < 980){
      setOpenSideBar(false)
    }
    onClick()
  }

  if (checkLink){
    return <a href={to} onClick={clickHandler} {...props} />
  }
  else{
    return <Link to={to} onClick={clickHandler} {...props} />
  }
}

export default SpecialLink