import { inDev } from "./Values"

const ipAddress = '192.168.1.2'
const devTest  = false
const localhost = devTest ? ipAddress : 'localhost'

export const apiUrl = inDev ? `http://${localhost}:8000` : 'https://api.hanotify.com'
export const filesUrl = inDev ? 'http://files.localhost:8080' : 'https://files.hanotify.store'
export const storeDomain = inDev ? `${localhost}:3001` : 'hanotify.store'
export const wsDomain = inDev ? `ws://${localhost}:8000` : 'wss://api.hanotify.com'
export const domainExtention = inDev ? `${localhost}:3001` : 'hanotify.store'
export const hanotifyUrl = inDev ? `http://${localhost}:3000` : 'https://hanotify.com'
