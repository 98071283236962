import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ClickOutsideComponent from '../ClickOutsideComponent'
import IconWithHover from '../IconWithHover'
import classes from './UserButton.module.css'
import Loader from '../Loader'
import useLogout from 'hooks/useLogout'
import { useBrowserContext } from 'store/browser-context'
import { useUserContext } from 'store/user-context'
import { translate } from 'utils/utils'

const UserButton=({size=22})=>{
    const [showParameters, setShowParameters]= useState(false)
    const {userData, storeCredit, soundMode, setSoundMode } = useUserContext()
    const {globalMessageA, isMobile} = useBrowserContext()
  
    useEffect(()=>{
      if (globalMessageA) setShowParameters(false)
    }, [globalMessageA]) 

    const {logout, loading} = useLogout()
    const logoutHandler = async()=>{
      const reponse = await logout()
      if (reponse) setShowParameters(false)
    }

    const {langTerms} = useBrowserContext()
    return(
      <div className='d-f g-4 align-items-center' >
        { !isMobile && userData && storeCredit !== null && <h4 style={{flexShrink: 0, color: 'var(--successColor)'}} className='no-select'> { storeCredit } { translate('DA') }</h4>}
        { !isMobile && userData && storeCredit === null && <div style={{ backgroundColor: 'var(--greyColor)', borderRadius: 4, height: 16, width: 60 }} />}
        <div>
          <ClickOutsideComponent listeningCondintion={showParameters}  onClickOutside={setShowParameters.bind(this, false)}>
            <IconWithHover iconClass="fa-solid fa-user" size={size} color={'var(--primaryColor)'} onClick={setShowParameters.bind(this, !showParameters)} />
            {showParameters &&(
                <div className={classes['user-paramters'] + ' container'} style={{[langTerms['right']]: 4}} >
                  {!userData &&
                    (<>
                      <Link to='/login' onClick={setShowParameters.bind(this, false)} className='d-f g-2 align-center p-2'><i className="fa-solid fa-user" style={{fontSize: 20, width: 26}} /> <h4>Login</h4></Link>
                      <Link to='/register' onClick={setShowParameters.bind(this, false)} className='d-f g-2 align-center p-2'><i className="fa-solid fa-user-plus" style={{fontSize: 20}} /> <h4>Register</h4></Link>
                    </>)
                  }
                  {userData &&
                    (<>
                      <div className='d-f g-3 align-center p-2 cursor-pointer no-event'>
                        <i className='fa-solid fa-user' style={{fontSize: 22}} />
                        <h4>{ userData.username }</h4>
                      </div>
                      <hr/>

                      { soundMode === 'Loud' && 
                        <div className='d-f g-2 align-center p-2 cursor-pointer' onClick={setSoundMode.bind(this, 'Silence')}>
                          <i className='fa-solid fa-volume-high'style={{fontSize: 18}} />
                          <h4 className='px-1'>{ translate(soundMode) }</h4>
                        </div>
                      }
                      { soundMode === 'Silence' && 
                      <div className='d-f g-2 align-center p-2 cursor-pointer' onClick={setSoundMode.bind(this, 'Loud')}>
                        <i className='fa-solid fa-volume-xmark'style={{fontSize: 18}} />
                        <h4 className='px-1'>{ translate(soundMode) }</h4>
                      </div>
                      }
                      <hr/>
                      <div disabled={loading} onClick={logoutHandler} className='d-f g-2 align-center p-2 cursor-pointer'>
                        <i className="fa-solid fa-right-to-bracket" style={{fontSize: 20, width: 26}} /> 
                        <h4 style={{flexGrow:1}}>{translate('Logout')}</h4>
                        { loading && <Loader diam={22}/>}
                      </div>
                    </>)
                  }
                </div>
              
                
            )}
          </ClickOutsideComponent>
        </div>
      </div>
        
    )
  }
  
export default UserButton