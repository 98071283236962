export const inDev = process.env.NODE_ENV === 'development'
export const androidUrl = "https://drive.google.com/file/d/1jpucaU6vgaEvPBI5ByMIgTyvt7A8w9lW/view?usp=sharing"


export const colorByPlan =  {
    "Basic": "#0011ab",
    'Beginner': "#3451fb",
    'Merchant': "#a63e02",
    'Professional': '#ff0000',
    'Experienced': '#22ab9b',
    'Advanced': "#5825b8",
} 
