import React, { useEffect, useRef, useState } from 'react'
import { useBrowserContext } from '../../store/browser-context'
import classes from './GlobalMessage.module.css'
import IconWithHover from 'components/IconWithHover'

const GlobalMessage = ({children}) => {
    const {globalMessageA, setGlobalMessageA} = useBrowserContext()
    const interval1 = useRef()
    const interval2 = useRef()
    const interval3 = useRef()

    const time = globalMessageA.manu ? 999999999 : (globalMessageA.time || 3000)
    const color = globalMessageA.color || 'var(--successColor)'
    useEffect(()=>{ 
        clearInterval(interval1.current)
        clearInterval(interval2.current)
        clearInterval(interval3.current)
        if (globalMessageA && notificationRef.current){ 
            interval1.current = setTimeout(()=>{
                notificationRef.current.style.transform= 'translateY(0%)';
                clearInterval(interval1.current)
            }, 500)
            interval2.current = setTimeout(()=>{
                notificationRef.current.style.transform= 'translateY(-100%)';
                clearInterval(interval2.current)
            }, time + 500)
            interval3.current = setTimeout(()=>{
                setGlobalMessageA(null)
                clearInterval(interval3.current)
            }, time + 1000)

        }
        return ()=>{
            clearInterval(interval1.current)
            clearInterval(interval2.current)
            clearInterval(interval3.current)
        }
    }, [globalMessageA])

    const close=()=>{
        notificationRef.current.style.transform= 'translateY(-100%)';
        clearInterval(interval2.current)
        setGlobalMessageA(null)
        clearInterval(interval3.current)
    }

    const notificationRef = useRef()
    const {langTerms} = useBrowserContext()
    return (
        <div
            ref={notificationRef} 
            className={classes['container']} 
            style={{
                backgroundColor: color
            }}
        
        >
            <i onClick={close} className='fa-solid fa-xmark p-2 cursor-pointer' style={{position: 'absolute', fontSize: 24, [langTerms['right']]: 0, top: 0, color: 'var(--containerColor)'}} />
            <div className='p-1'  style={{ color: 'var(--containerColor)', ['margin'+langTerms['Right']]: 32 }} dangerouslySetInnerHTML={{__html: children}} />
        </div>
    )
}

export default GlobalMessage