import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react"
import browserDataJson from "../json/browser-data.json"
import { axiosTr, getDefaultLanguage, hexToRgb, translate } from "../utils/utils"
import useGetCurrentScreenWidth from "hooks/useGetScreenWidth"
import { useUserContext } from "./user-context"
import { colorByPlan } from "constants/Values"

const {browserData : BD} = browserDataJson

const ltr = {
    left: 'left',
    right: 'right',
    Left: 'Left',
    Right: 'Right',
    l: 'l',
    r: 'r',
}
const rtl = {
    left: 'right',
    right: 'left',
    Left: 'Right',
    Right: 'Left',
    l: 'r',
    r: 'l',
}

const BrowserContext = createContext({
    theme: 'light',
    toggleTheme: ()=>{},
    browserData: BD,
    setBrowserData: ()=>{},
    colors: BD.colors['light'],
    setloading: ()=>{},
    loading: false,
    setMetaData: ({title, description})=>{},
    globalMessageA: {
        color: 'var(--primaryColor)',
        time: 4000,
        children: null,
        manu: false
    }, 
    setGlobalMessageA: ({color, time, children, manu})=>{},
    langTerms: ltr
    
})

const root = document.getElementById('root')

if(!localStorage.getItem('language')){
    localStorage.setItem('language', getDefaultLanguage())
}

const BrowserContextProvider=({children})=>{
    // language
    const [lang, setLang] = useState(localStorage.getItem('language'))
    const langTerms = lang === 'ar' ? rtl : ltr
    const firstCycleDone = useRef(false)
    useEffect(()=>{
        document.documentElement.setAttribute('lang', lang)
        document.documentElement.setAttribute('dir', lang === 'ar' ? 'rtl' : 'ltr')
        if (!firstCycleDone.current){
            firstCycleDone.current = true;
            return
        }
        localStorage.setItem('language', lang)
        axiosTr.defaults.headers['Accept-Language'] = lang;
        window.location = window.location
    }, [lang])

    
    // theme
    const defaultTheme = localStorage.getItem('theme') || 'light'
    const [theme, setTheme] = useState(defaultTheme)
    const toggleTheme = ()=> setTheme(state =>state === 'light' ? 'dark' : 'light')
    
    // colors
    const {userData} = useUserContext()
    const colors = useMemo(()=>{
        let _colors = BD.colors[theme]
        if(userData){
            const planName = userData.plan.name_en
            _colors = {
                ..._colors,
                primaryColor:colorByPlan[planName]
            };
        }
        return _colors
    }, [theme, userData?.plan])


    useEffect(()=>{
        for (let key in colors) {
            root.style.setProperty(`--${key}`, colors[key])
            root.style.setProperty(`--${key}-rgb`, hexToRgb(colors[key]))
        }
            
    }, [theme, colors])

    useEffect(()=>{
          if('ReactNativeWebView' in window){
            window.ReactNativeWebView.postMessage(JSON.stringify({ 
              type: 'BROWSER_DATA_CHANGED',
              lang,
            }))
          }
     }, [lang, colors])
    

   
    useEffect(()=>{
        localStorage.setItem('theme', theme)
    }, [theme])
   
    // browser data
    const [browserData, setBrowserData] = useState(BD)

    // loading
    const [loading, setloading]=useState(false)

    // title and description
    const [metaData, setMetaData] = useState({
        title: translate('Hanotify'),
        description: translate('Obtenir votre e-store dans hanotify')
    })

    useEffect(()=>{
        document.getElementById('page-title').innerHTML = metaData.title
        document.getElementById('meta-description').setAttribute('content', metaData.description)
    }, [metaData])

    // Global message notification
    const [globalMessageA, setGlobalMessageA] = useState(null)


    const screenWidth = useGetCurrentScreenWidth();
    const [openSideBar, setOpenSideBar]= useState(screenWidth > 980)


    // default context value
    const md = screenWidth <= 980 ? 768 : ( openSideBar ? 1008 : 851 )
    const lg = screenWidth <= 980 ? 992 : ( openSideBar ? 1232 : 1075 )
    const defaultBrowserValue={
        theme,
        toggleTheme,
        browserData,
        setBrowserData,
        colors,
        setloading,
        loading,
        setMetaData,
        setGlobalMessageA,
        globalMessageA,
        lang,
        setLang,
        langTerms,
        openSideBar, setOpenSideBar,
        isMobile : screenWidth < 450,
        screenWidth,
        lg, md
    }
    
    return(
        <BrowserContext.Provider value={defaultBrowserValue}>
            <div style={{display: "flex", flexDirection: 'column'}} id='app'>
                { browserData && children} 
            </div>
        </BrowserContext.Provider>
    )
}

export default BrowserContextProvider
export const useBrowserContext = ()=> useContext(BrowserContext)
