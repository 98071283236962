import React from 'react'
import classes from './Header.module.css'
import IconWithHover from '../IconWithHover'
import { useBrowserContext } from '../../store/browser-context'
import GlobalMessage from '../notifications/GlobalMessage'
import logo from '.././../assets/logo.png'
import {useUserContext} from '../../store/user-context'
import UserButton from './UserButton'
import SpecialLink from 'components/SpecialLink'
import Button from 'components/Button'
import GlobalDialog from 'components/notifications/GlobalDialog'

const Header = () => {
  const {globalMessageA, setOpenSideBar, isMobile}=useBrowserContext()
  const {userData}= useUserContext()
  
  const {screenWidth, sidebBarType} = useBrowserContext()
  const smallScreen = sidebBarType === 1 ? false : ( sidebBarType === 2 ? true : ( screenWidth <= 980 ))
  return (
    <>
      <header id='header' style={{position: 'sticky', top: 0, zIndex:1002}}>
        <div className={classes.header}>
          { smallScreen && <div className='d-flex g-3 align-items-center'>
            <IconWithHover size={28} iconClass="fa-solid fa-bars" color={'var(--primaryColor)'} onClick={()=>setOpenSideBar(true)} />
          </div>
          }
          {
            !smallScreen && <div/>
          }
          <button><img src={logo} width={100} style={{objectFit: 'contain', margin: '0 12px'}} /></button>
          <div className='d-flex g-3'>
            { !isMobile && <SpecialLink to='/plans'><Button outline className='px-2 py-0 italic'>{ userData.plan.name }</Button></SpecialLink>} 
            <UserButton />
          </div>
        </div>
        <div  style={{position: 'sticky', top: 0}}>
          {globalMessageA && <GlobalMessage children={globalMessageA.children} />}
        </div>
      </header>
      <div style={{position: 'sticky', top: 0, zIndex:1001}}>
        {globalMessageA && <GlobalMessage children={globalMessageA.children} />}
      </div>
      <GlobalDialog />
    </>
    
  )
}

export default Header